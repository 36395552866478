import {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {styled, lighten, darken} from "@mui/material";

import {AzaBox} from "../../../../../components/mui/AzaBox";
import {AzaGridContainer, AzaGridItem} from "../../../../../components/mui/AzaGrid";
import {
    actionSetProductAssets,
    selectListProducts,
    actionSetProductAssetSelected,
    actionSetProductInfoSelected,
    actionSetProductOptionSelected,
    selectProductOptionSelected,
} from "../../../reducers/createNewCampaignSlice";
import {
    calloutHandler,
    descriptionHandler,
    headlineHandler, keywordHandler,
    longHeadlineHandler
} from "../addAssetsUtility/inputConfigData";
import {assetType} from "../../../../../pages/authenticated/assetsEdition/constants";
import {generateNewInputObj} from "../addAssetsUtility/generateNewInputObj";
import {AzaAutocomplete} from "../../../../../components/mui/AzaAutocomplete";
import {AzaTextFieldBasic} from "../../../../../components/mui/AzaTextField";
import {useTranslation} from "react-i18next";

const creatNewProductAssetsGroup = (data) => {
    const {scope, siteId, productId, language} = data;
    const productAssetsGroup = {
        [assetType.HEADLINE]: [],
        [assetType.LONG_HEADLINE]: [],
        [assetType.DESCRIPTION]: [],
        [assetType.CALLOUT]: [],
        [assetType.KEYWORD]: [],
    }

    const minTitleNumber = headlineHandler.minInputs;
    const minLongTitleNumber = longHeadlineHandler.minInputs;
    const minDescriptionNumber = descriptionHandler.minInputs;
    const minCalloutNumber = calloutHandler.minInputs;
    const minKeywordNumber = keywordHandler.minInputs;

    for (const inputType in productAssetsGroup) {
        switch (inputType) {
            case assetType.HEADLINE:
                productAssetsGroup[inputType] = generateNewInputObj(minTitleNumber, scope, siteId, inputType, productId, language);
                break;
            case assetType.LONG_HEADLINE:
                productAssetsGroup[inputType] = generateNewInputObj(minLongTitleNumber, scope, siteId, inputType, productId, language);
                break;
            case assetType.DESCRIPTION:
                productAssetsGroup[inputType] = generateNewInputObj(minDescriptionNumber, scope, siteId, inputType, productId, language);
                break;
            case assetType.CALLOUT:
                productAssetsGroup[inputType] = generateNewInputObj(minCalloutNumber, scope, siteId, inputType, productId, language);
                break;
            case assetType.KEYWORD:
                productAssetsGroup[inputType] = generateNewInputObj(minKeywordNumber, scope, siteId, inputType, productId, language);
                break;
            default:
                console.log(inputType + ' : Unexpected assets type');
        }
    }

    return productAssetsGroup;
}

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

const SelectProduct = ({productIdsWithAssets, productWithAssets, scope, siteId}) => {
    const {t} = useTranslation();
    const listProducts = useSelector(selectListProducts);
    const optionSelected = useSelector(selectProductOptionSelected);
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    // hardcoded
    const language = 'fr'

    const createProdAssetsHelper = useCallback((prodId, selectedOption) => {
        const data = {
            scope: scope,
            siteId: siteId,
            productId: prodId,
            language: language,
        }
        const newProductAssetsGroup = creatNewProductAssetsGroup(data);
        dispatch(actionSetProductAssetSelected(newProductAssetsGroup));
        dispatch(actionSetProductAssets({...productWithAssets, [prodId]: newProductAssetsGroup}));
        dispatch(actionSetProductOptionSelected(selectedOption));
    }, [scope, siteId, dispatch, productWithAssets]);

    const handleChange = useCallback((e, newValue) => {
        if (newValue) {
            dispatch(actionSetProductInfoSelected({prodId: newValue.id, prodName: newValue.title}));
            dispatch(actionSetProductOptionSelected(newValue));
            if (productIdsWithAssets.length > 0 && productIdsWithAssets.includes(newValue.id.toString())) {
                dispatch(actionSetProductAssetSelected(productWithAssets[newValue.id]));
            } else {
                createProdAssetsHelper(newValue.id, newValue);
            }
        }
    }, [dispatch, productIdsWithAssets, productWithAssets, createProdAssetsHelper]);

    const options = useMemo(() => {
        const prodList = listProducts.map((product) => {
            if (productIdsWithAssets.includes(product.id.toString())) {
                return {
                    groupName: t('product.group-with-assets'),
                    order: 'A',
                    ...product,
                }
            } else {
                return {
                    groupName: t('product.group-empty'),
                    order:'B',
                    ...product,
                }
            }
        });

        return prodList.sort((a, b) => -b.order.localeCompare(a.order));
    }, [listProducts, productIdsWithAssets, t]);

    useEffect(() => {
        if(!optionSelected) {
            if(productIdsWithAssets[0]) {
                dispatch(actionSetProductInfoSelected({prodId: options[0].id, prodName: options[0].title}));
                dispatch(actionSetProductAssetSelected(productWithAssets[options[0].id]));
                dispatch(actionSetProductOptionSelected(options[0]));
            } else {
                createProdAssetsHelper(options[0].id, options[0]);
                dispatch(actionSetProductInfoSelected({prodId: options[0].id, prodName: options[0].title}));
            }
        }
    }, [optionSelected, productIdsWithAssets, productWithAssets, options, dispatch, createProdAssetsHelper]);

    return (
        <AzaBox sx={{mb: '30px'}}>
            <AzaBox sx={{mb: '20px', display: 'flex'}}>
                <AzaBox sx={{fontWeight: 'bold', mr: '5px'}}>{t('product.title')}</AzaBox>
            </AzaBox>
            <AzaGridContainer>
                <AzaGridItem xs={12} md={6}>
                    <AzaAutocomplete
                        options={options}
                        value={optionSelected}
                        inputValue={inputValue}
                        onChange={handleChange}
                        onInputChange={(e, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        groupBy={(option) => option.groupName}
                        getOptionLabel={(option) => (option.title + ' (' + option.id + ')') ?? ""}
                        renderInput={(params) => <AzaTextFieldBasic {...params} label={t('product.select.placeholder')}/>}
                        renderGroup={(params) => (
                            <li key={params.key}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>)
                        }
                    />
                </AzaGridItem>
            </AzaGridContainer>
        </AzaBox>
    );
};

export default SelectProduct;