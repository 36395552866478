import {DrawerHeader} from "../../App.style";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import React, {useState} from "react";
import MobileFooter from "../../components/mobileFooter/MobileFooter";
import {AdminModeProvider} from "../../features/adminMode/adminModeContext";
import {SideBarElements} from "./SideBarElements";
import {useScreenSize} from "../../app/globalHooks";
import {AzaBox} from "../../components/mui/AzaBox";
import {PaymentModeProvider} from "../../containers/payment/paymentContext";

export const AuthenticatedPages = () => {
    const [overlapHelp, setOverlapHelp] = useState(true);
    const {isSmallScreen} = useScreenSize();
    const marginLeftMain = '65px';

    return (
        <AdminModeProvider>
            <PaymentModeProvider>
                <AzaBox sx={{display: 'flex', minHeight:"100vh"}}>
                    <SideBarElements setOverlapHelp={setOverlapHelp}/>
                    <AzaBox component={`main`} sx={{
                        flexGrow: 1,
                        p: isSmallScreen ? '24px  9px 85px' : 3,
                        width: overlapHelp ? `calc(100% - ${marginLeftMain})` : '100%',
                        ml: (overlapHelp && !isSmallScreen) ? marginLeftMain : '0',
                    }}
                    >
                        <DrawerHeader/>
                        <AuthenticatedRoutes/>
                    </AzaBox>
                    {isSmallScreen ? <MobileFooter/> : null}
                </AzaBox>
            </PaymentModeProvider>
        </AdminModeProvider>

    )
}
