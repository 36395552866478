import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {actionUpdateAssetScope, selectAssetScope, selectListProducts} from "../../../reducers/createNewCampaignSlice";
import {AzaBox} from "../../../../../components/mui/AzaBox";
import {AzaTabs, AzaTab} from "../../../../../components/mui/AzaTabs";
import GenerateAssets from "./GenerateAssets";
import {useAdminModeProvider} from "../../../../../features/adminMode/adminModeContext";

const AdAssetToolbar = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const assetScopeInfo = useSelector(selectAssetScope);
    const listProducts = useSelector(selectListProducts);
    const {isAdmin, adminMode} = useAdminModeProvider();

    const handleChange = (event, newTab) => {
        const data = {
            activeTab: newTab,
            scopeName: event.target.name
        }
        dispatch(actionUpdateAssetScope(data));
    };
    const brand_productTabs = [
        {
            name: t('brand.title'),
            assetScope: 'Site',
            disabled: false
        },
        {
            name: t('product.title'),
            assetScope: 'Product',
            // check if exist at list one product
            disabled: listProducts.length < 1
        },
    ];

    return (
        <AzaBox
            sx={{
                display:'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 15px'
            }}
        >
            <AzaBox sx={{display: 'flex', alignItems: 'center'}}>
                <AzaTabs
                    value={assetScopeInfo.activeTab}
                    onChange={handleChange}
                >
                    {brand_productTabs.map((el, index) => {
                        return (
                            <AzaTab key={el.name + '-' + index} value={index} label={el.name} name={el.assetScope} disabled={el.disabled}/>
                        )
                    })}
                </AzaTabs>
            </AzaBox>
            <AzaBox>
                {(isAdmin && adminMode) && <GenerateAssets/>}
            </AzaBox>
        </AzaBox>
    )
};

export default AdAssetToolbar;