import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {
    calloutHandler,
    descriptionHandler,
    headlineHandler,
    longHeadlineHandler,
    imgHandler,
    logoHandler
} from "./addAssetsGroup/addAssetsUtility/inputConfigData";
import AddImages from "../addImages/AddImages";
import BrandName from "./addAssetsGroup/BrandName";
import Headlines from "./addAssetsGroup/Headlines";
import LongHeadlines from "./addAssetsGroup/LongHeadlines";
import Descriptions from "./addAssetsGroup/Description";
import Callouts from "./addAssetsGroup/Callouts";
import {getSiteAssets} from "../../../utils/getAssets/assetsUtils";
import {useSiteId, useUser} from "../../../app/globalHooks";
import {
    actionSetDescriptions,
    actionSetHeadlines,
    actionSetAssetsWasLoaded,
    selectAssetsWasLoaded,
    actionSetLongHeadlines,
    actionSetCallouts,
    actionSetCheckerSiteId,
    selectCheckerSiteId,
    actionSetOnLoadingState,
    selectOnLoadingState,
    selectAssetsLoaded,
    actionSetAssetsLoaded,
    actionSetKeywords,
    actionSetListProducts,
    selectAssetScope,
    actionSetProductAssets,
    actionSetProductInfoSelected,
    selectProductAssets,
    actionSetProductAssetSelected,
    selectProductAssetSelected,
    selectProductInfoSelected,
    actionSetProductOptionSelected,
    actionSetLogo,
    actionRemoveLogo,
    actionRemoveImage,
    actionSetImages, actionRemoveAllPictures,
} from "../reducers/createNewCampaignSlice";
import {setSiteLogo} from "../../../app/globalSlice";
import {assetType} from "../../../pages/authenticated/assetsEdition/constants";
import CustomizableSkeleton from "../../../components/skeleton/CustomizableSkeleton";
import {AzaGrid, AzaGridItem} from "../../../components/mui/AzaGrid";
import BaseCardTemplate from "../createNewCampaignUtile/BaseCardTemplate";
import {getSiteProducts} from "../../../pages/authenticated/assetsEdition/utils";
import SelectProduct from "./addAssetsGroup/selectProduct/SelectProduct";
import AdAssetToolbar from "./addAssetsGroup/addAssetsUtility/AdAssetToolbar";
import {useFileStoreProvider} from "../../../features/fileStoreContext";
import {methodType, siteBackendFetchRaw} from "../../../utils/backendHelper";
import {API_GET_POST_SITE_LOGO} from "../../../utils/constant";

const AdAssets = () => {
    const {t} = useTranslation();
    const checkerSiteId = useSelector(selectCheckerSiteId);
    const {user} = useUser();
    const site_id = useSiteId();
    const isDataLoaded = useSelector(selectAssetsWasLoaded);
    const isOnLoading = useSelector(selectOnLoadingState);
    const dispatch = useDispatch();
    const isLoaded = useSelector(selectAssetsLoaded);
    const assetsScope = useSelector(selectAssetScope);
    const productWithAssets = useSelector(selectProductAssets);
    const assetsDataProduct = useSelector(selectProductAssetSelected);
    const productId = useSelector(selectProductInfoSelected).prodId;
    const minHeadlines = headlineHandler.minInputs;
    const maxHeadlines = headlineHandler.maxInputs;
    const minLongHeadlines = longHeadlineHandler.minInputs;
    const maxLongHeadlines = longHeadlineHandler.maxInputs;
    const minDescriptions = descriptionHandler.minInputs;
    const maxDescriptions = descriptionHandler.maxInputs;
    const minCallouts = calloutHandler.minInputs;
    const maxCallouts = calloutHandler.maxInputs;
    const minImg = imgHandler.minInputs;
    const maxImg = imgHandler.maxInputs;
    const productIdsWithAssets = Object.keys(productWithAssets);
    const {addFile, removeFile, allFiles, clearAllFiles} = useFileStoreProvider();

    // Site scope VS Product scope: put in Slice => when toggle btn Brand/Product change assetsScope
    const handleUpdateAssets = useCallback((filtered_data) => {
        dispatch(actionSetOnLoadingState(false));
        dispatch(actionSetHeadlines({data: filtered_data.Site[assetType.HEADLINE]}));
        dispatch(actionSetLongHeadlines({data: filtered_data.Site[assetType.LONG_HEADLINE]}));
        dispatch(actionSetDescriptions({data: filtered_data.Site[assetType.DESCRIPTION]}));
        dispatch(actionSetCallouts({data: filtered_data.Site[assetType.CALLOUT]}));
        dispatch(actionSetKeywords({data: filtered_data.Site[assetType.KEYWORD]}));

        // re-initialisation of product group, when site_id change
        dispatch(actionSetProductInfoSelected({}));
        dispatch(actionSetProductAssetSelected({}));
        dispatch(actionSetProductOptionSelected(null));
        if(Object.keys(filtered_data.Product).length > 0) {
            dispatch(actionSetProductAssets(filtered_data.Product));
        } else {
            dispatch(actionSetProductAssets({}));
        }

        dispatch(actionSetAssetsWasLoaded(true));
    }, [dispatch]);

    const handleListProducts = useCallback((products) => {
        // limited to 500 products
        const restrictedList = products.slice(0, 500);
        dispatch(actionSetListProducts(restrictedList));
    }, [dispatch]);

    const handleError = useCallback((error) => {
        console.log('Some error occurred: ' + error);
        dispatch(actionSetAssetsLoaded(true));
    }, [dispatch]);

    const getLogo = useCallback(async() => {
        const sitelogo = await siteBackendFetchRaw({
            user: user,
            site_id: site_id,
            path: API_GET_POST_SITE_LOGO,
            method: methodType.GET,
            queryParams: {data:true},
        });
        const siteLogoData = {
            error: {
                status: false,
                message: '',
            },
            is_updated: false,
            files: [{
                preview: sitelogo,
                name: 'Logo',
                path: 'site_logo',
                size: ''
            }],
        };
        addFile('logo', siteLogoData.files[0].name, siteLogoData.files[0]);
        dispatch(setSiteLogo(siteLogoData));
    }, [dispatch, site_id, user, addFile]);
    // refresh assets when user or site_id change
    useEffect(() => {
        // Check if 'site_id' was changed
        if (checkerSiteId !== site_id && isDataLoaded) {
            dispatch(actionSetAssetsWasLoaded(false));
        }

        if (user && site_id && !isOnLoading && !isDataLoaded) {
            getLogo();
            getSiteAssets(user, site_id, handleUpdateAssets, handleError);
            dispatch(actionSetOnLoadingState(true));
            dispatch(actionSetCheckerSiteId(site_id));
            dispatch(actionSetAssetsLoaded(true));
            getSiteProducts(user, site_id, handleListProducts);
        }
    }, [checkerSiteId, handleUpdateAssets, handleError, isOnLoading, isDataLoaded, user, site_id, dispatch, handleListProducts, getLogo]);

    const logo = allFiles('logo');
    const setLogo = (files) => {
        files.map(e => addFile('logo', e.name, e))
        dispatch(actionSetLogo({data: files.map(e => e.name)}))
    }
    const delLogo = (file) => {
        removeFile('logo', file.name);
        dispatch(actionRemoveLogo(file.name))
    }

    const images = allFiles('images');
    const setImages = (files) => {
        files.map(e => addFile('images', e.name, e))
        dispatch(actionSetImages({data: files.map(e => e.name)}))
    }
    const delImages = (file) => {
        removeFile('images', file.name);
        dispatch(actionRemoveImage(file.name))
    }

    useEffect(() => {
        if (checkerSiteId !== site_id) {
            clearAllFiles('logo');
            clearAllFiles('images');
            dispatch(actionRemoveAllPictures());
        }
    }, [checkerSiteId, site_id, clearAllFiles, dispatch]);


    return isLoaded ? (
        <BaseCardTemplate title={''} component={AdAssetToolbar}>
            {assetsScope.scopeName === 'Site' && <BrandName
                inputGroupName={t('brand-name')}
                inputPlaceholder={t('brand-name')}
            />}
            {assetsScope.scopeName === 'Product' && (
                <SelectProduct
                    productIdsWithAssets={productIdsWithAssets}
                    productWithAssets={productWithAssets}
                    scope={assetsScope.scopeName}
                    siteId={site_id}
                />
            )}
            <Headlines
                inputGroupName={t('headline', {count: 2})}
                addNewInputBtnName={t('headline', {count: 1})}
                toolTipsText={t('tooltips-headlines')}
                configData={headlineHandler}
                currentSiteId={site_id}
                assetsScope={assetsScope.scopeName}
                assetsDataProduct={assetsDataProduct}
                productId={productId}
                // is it ok this realisation?
                minMaxIndication={t('add-assets', {
                    from: minHeadlines, to: maxHeadlines, val: '$t(headlines)',
                    interpolation: {
                        skipOnVariables: false,
                    },
                })}
            />

            <LongHeadlines
                inputGroupName={t('long-headline', {count: 2})}
                addNewInputBtnName={t('long-headline', {count: 1})}
                toolTipsText={t('tooltips-headlines')}
                configData={longHeadlineHandler}
                currentSiteId={site_id}
                assetsScope={assetsScope.scopeName}
                assetsDataProduct={assetsDataProduct}
                productId={productId}
                minMaxIndication={t('add-assets', {
                    from: minLongHeadlines, to: maxLongHeadlines, val: '$t(long-headlines)',
                    interpolation: {
                        skipOnVariables: false,
                    },
                })}
            />

            <Descriptions
                inputGroupName={t('description', {count: 2})}
                addNewInputBtnName={t('description', {count: 1})}
                toolTipsText={t('tooltips-headlines')}
                configData={descriptionHandler}
                currentSiteId={site_id}
                assetsScope={assetsScope.scopeName}
                assetsDataProduct={assetsDataProduct}
                productId={productId}
                minMaxIndication={t('add-assets', {
                    from: minDescriptions, to: maxDescriptions, val: '$t(descriptions)',
                    interpolation: {
                        skipOnVariables: false,
                    },
                })}
            />

            <Callouts
                inputGroupName={t('callout', {count: 2})}
                addNewInputBtnName={t('callout', {count: 1})}
                toolTipsText={t('tooltips-headlines')}
                configData={calloutHandler}
                currentSiteId={site_id}
                assetsScope={assetsScope.scopeName}
                assetsDataProduct={assetsDataProduct}
                productId={productId}
                minMaxIndication={t('add-assets', {
                    from: minCallouts, to: maxCallouts, val: '$t(callouts)',
                    interpolation: {
                        skipOnVariables: false,
                    },
                })}
            />

            {/* NEED TO BE ADJUSTED FOR PRODUCT TAB */}
            <AzaGrid container spacing={2}>
                <AzaGridItem xs={12} md={6}>
                    <AddImages
                        inputGroupName={t('images.add-logo')}
                        configData={logoHandler}
                        minMaxIndication={t('images.add-img-logo')}
                        isLogo={true}
                        images={logo}
                        addImage={setLogo}
                        removeImage={delLogo}
                    />
                </AzaGridItem>
                {/*{assetsScope.scopeName === 'Site' && */}
                    <AzaGridItem xs={12} md={6}>
                    <AddImages
                        inputGroupName={t('images.add-image')}
                        configData={imgHandler}
                        minMaxIndication={t('add-assets', {
                            from: minImg, to: maxImg, val: '$t(images.images)',
                            interpolation: {
                                skipOnVariables: false,
                            },
                        })}
                        images={images}
                        addImage={setImages}
                        removeImage={delImages}
                    />
                </AzaGridItem>
            {/*}*/}
            </AzaGrid>
        </BaseCardTemplate>
    ) : (<>
        <CustomizableSkeleton numbCols={2} numbRows={2} />
        <CustomizableSkeleton numbCols={2} numbRows={2} />
        <CustomizableSkeleton numbCols={2} numbRows={2} />
        <CustomizableSkeleton numbCols={2} numbRows={2} />
    </>)
};

export default AdAssets;

