import React from "react";
import {UserSettingsButton} from "./UserSettingsMenu";

import './TopBar.css';
import {AdminModeBranch, AdminModeRequired, AdminModeToggleButton} from "../../features/adminMode/adminModeComponents";
import {CreditChip, PlanChip} from "./TopBarChips";
import {SiteSelector} from "../../widgets/siteSelector/SiteSelector";
import {Heartbeat} from "../../widgets/Heartbeat/Heartbeat";
import {AttributionUpdater} from "../../features/attribution/AttributionUpdater";
import {AzaToolBar} from "../../components/mui/AzaToolBar";
import {AzaAppBar} from "../../components/mui/AzaAppBar";
import {AzaIconButton} from "../../components/mui/AzaButton";
import {AzaBox} from "../../components/mui/AzaBox";
import {AzaMenuFoldIcon, AzaMenuUnFoldIcon} from "../../components/mui/AzaIcons";
import {AzaTypography} from "../../components/mui/AzaTypography";
import {styled} from "@mui/material";
import {NotificationWidget} from "../../widgets/NotificationWidget/NotificationWidget";

const openedMixin = (theme) => ({
    width: 'calc(100% - 240px)',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.complex,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(100% - 65px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(100% - 65px)`,
    },
});

const AppBarContainer = styled(AzaAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => {
    return ({
        boxShadow: 'none',
        borderBottom: '1px solid #f0f0f0',
        zIndex: theme.zIndex.drawer + 1,
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    })
});


const TopBar = ({open, handleDrawerOpen, smallScreen}) => {

    return (
        <>
            <AppBarContainer position="fixed" open={open}>
                <AzaToolBar>
                    <AzaIconButton
                        onClick={handleDrawerOpen}
                        sx={{
                            mr: 2,
                        }}
                    >
                        {open && <AzaMenuFoldIcon/>}
                        {!open && <AzaMenuUnFoldIcon/>}
                    </AzaIconButton>
                    <Heartbeat/>
                    <AttributionUpdater/>
                    {!smallScreen && <SiteSelector/>}
                    <AzaTypography
                        sx={{
                            color: 'black',
                            // opacity: 0.5,
                            flexGrow: 1
                        }}
                    >
                    </AzaTypography>
                    {smallScreen ? null : <>
                        <AzaBox sx={{
                            mr: "25px",
                            display: "flex",
                        }}>

                            <AdminModeBranch needAdmin={true}><PlanChip/></AdminModeBranch>
                            <CreditChip/>
                        </AzaBox>
                    </>}

                    <AdminModeToggleButton/> {/* Toggle to enable or disable admin mode */}
                    <AdminModeRequired disableTheme={true}  >
                        <NotificationWidget/>
                    </AdminModeRequired>
                    <UserSettingsButton/>
                </AzaToolBar>
            </AppBarContainer>
        </>
    )
}

export default TopBar;
