import {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {AzaBox} from "../../../../components/mui/AzaBox";
import BaseCardTemplate from "../../createNewCampaignUtile/BaseCardTemplate";
import {AzaGridContainer, AzaGridItem} from "../../../../components/mui/AzaGrid";
import {AzaTextField} from "../../../../components/mui/AzaTextField";
import {actionSetCampaignName, selectCampaignName} from "../../reducers/createNewCampaignSlice";
import {AzaAlert} from "../../../../components/mui/AzaAlert";

const CampaignSettings = () => {
    const {t} = useTranslation();
    const campaignNameData = useSelector(selectCampaignName);
    const [value, setValue] = useState(campaignNameData.new_name);
    const dispatch = useDispatch();

    const handleValue = (e) => {
        setValue(e.target.value);
    }

    const handleSetValue = (e) => {
        dispatch(actionSetCampaignName({new_name: e.target.value}));
    }

    return (
        <AzaBox sx={{mb: '30px'}}>
            <BaseCardTemplate title={t('campaigns_data.campaign-settings')}>
                <AzaBox sx={{mb: '30px'}}>
                    <AzaBox sx={{mb: '20px', display: 'flex'}}>
                        <AzaBox sx={{fontWeight: 'bold', mr: '5px'}}>{t('campaigns_data.campaign_name')}</AzaBox>
                    </AzaBox>
                    <AzaGridContainer>
                        <AzaGridItem xs={12} md={6}>
                            <AzaTextField
                                required
                                error={campaignNameData?.error?.status}
                                helperText=''
                                onChange={handleValue}
                                onBlur={handleSetValue}
                                value={value}
                                size="small"
                                placeholder={t('campaigns_data.campaign_name')}
                                label={t('campaigns_data.campaign_name')}
                            />
                        </AzaGridItem>
                    </AzaGridContainer>
                    {campaignNameData?.error?.status &&
                        <AzaAlert severity="error" sx={{marginTop: '15px'}}>
                            <AzaBox>
                                {
                                    campaignNameData?.error?.message && <AzaBox>{campaignNameData.error.message}</AzaBox>
                                }
                            </AzaBox>
                        </AzaAlert>}
                </AzaBox>
            </BaseCardTemplate>
        </AzaBox>
    );
};

export default CampaignSettings;