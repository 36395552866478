import {backendFetch} from "../backendHelper";
import {API_ASSETS_LIST} from "../constant";
// should we change the place of these files (pages/authenticated/assetsEdition/constants)?
import {assetScope, assetType} from "../../pages/authenticated/assetsEdition/constants";
import {
    calloutHandler,
    descriptionHandler,
    headlineHandler,
    longHeadlineHandler,
    keywordHandler,
} from "../../containers/createNewCampaign/createNewCampaignGroup/addAssetsGroup/addAssetsUtility/inputConfigData";

const generateTemplate = (idGenerated, scope, siteId, textType) => {
    return  {
        cleaned_text: "",
        enabled: true,
        id: idGenerated,
        language: 'fr',
        origin: "Customer",
        product_id: null,
        scope: scope,
        site_id: siteId,
        text: "",
        text_type: textType,
    };
}

const generateNewInputObj = (assetsDeficit, scope, siteId, textType) => {
    const assetsArr = [];
    for (let i = 0; i < assetsDeficit; i++) {
        let idGenerated = Date.now() + '-' + Math.floor(Math.random() * 1000);
        assetsArr.push(generateTemplate(idGenerated, scope, siteId, textType));
    }

    return assetsArr;
};

export const productFilter = (products) => {
    const arrayOfProduct = {};
    for(const el of products) {
        // check if obj exist, if not, create new obj, if yes, just add this obj
        if(arrayOfProduct[el.product_id]) {
            arrayOfProduct[el.product_id][el.text_type].push(el);
        } else {
            arrayOfProduct[el.product_id] = {
                [assetType.HEADLINE]: [],
                [assetType.LONG_HEADLINE]: [],
                [assetType.DESCRIPTION]: [],
                [assetType.CALLOUT]: [],
                [assetType.KEYWORD]: [],
            };
            arrayOfProduct[el.product_id][el.text_type].push(el);
        }
    }
    return arrayOfProduct;
}

export const filteringData = (json_data, siteId) => {
    const filter_data_helper = {
        'Site': [],
        'Product': []
    };
    // Final structure:
    const filtered_json_data = {
        'Site': {
            [assetType.HEADLINE]: [],
            [assetType.LONG_HEADLINE]: [],
            [assetType.DESCRIPTION]: [],
            [assetType.CALLOUT]: [],
            [assetType.KEYWORD]: [],
        },
        'Product': {
            [assetType.HEADLINE]: [],
            [assetType.LONG_HEADLINE]: [],
            [assetType.DESCRIPTION]: [],
            [assetType.CALLOUT]: [],
            [assetType.KEYWORD]: [],
        }
    }

    // Remove asset with enabled=false
    json_data = json_data.filter(asset => asset.enabled);

    filter_data_helper['Site'] = json_data.filter(asset => {
        if(asset.scope === assetScope.PRODUCT) {
            filter_data_helper['Product'].push(asset);
        }
        return asset.scope === assetScope.SITE;
    });
    // Order by id

    if(filter_data_helper['Product'].length < 1) {
        filtered_json_data['Product'] = {};
    } else {
        filtered_json_data['Product'] = productFilter(filter_data_helper['Product']);
    }

    filter_data_helper['Site'].sort((a, b) => a.id - b.id);

    /* Order by "origin" => "Custom" first, here just by chance we have data
    * which arrive with a needed letter order:
    * "Custom" should be first
    * "Generated" second
    * "Site" third
    */
    const compare = ( a, b ) => {
        if ( a.origin < b.origin ){
            return -1;
        }
        if ( a.origin > b.origin ){
            return 1;
        }
        return 0;
    }
    filter_data_helper['Site'].sort(compare);

    // Get max value of assets possible for each category, so not to load more than necessary assets to the store
    // If it will be more than max needed, can cause the problems when deleting items
    let titleNumber = headlineHandler.maxInputs;
    let longTitleNumber = longHeadlineHandler.maxInputs;
    let descriptionNumber = descriptionHandler.maxInputs;
    let calloutNumber = calloutHandler.maxInputs;
    let keywordNumber = keywordHandler.maxInputs;

    const scope = 'Site';
    for (const el of filter_data_helper[scope]) {
        switch (el.text_type) {
            case assetType.HEADLINE:
                if (titleNumber > 0) {
                    filtered_json_data[scope][assetType.HEADLINE].push(el);
                    titleNumber--;
                }
                break;
            case assetType.LONG_HEADLINE:
                if (longTitleNumber > 0) {
                    filtered_json_data[scope][assetType.LONG_HEADLINE].push(el);
                    longTitleNumber--;
                }
                break;
            case assetType.DESCRIPTION:
                if (descriptionNumber > 0) {
                    filtered_json_data[scope][assetType.DESCRIPTION].push(el);
                    descriptionNumber--;
                }
                break;
            case assetType.CALLOUT:
                if(calloutNumber > 0) {
                    filtered_json_data[scope][assetType.CALLOUT].push(el);
                    calloutNumber--;
                }
                break;
            case assetType.KEYWORD:
                if(keywordNumber > 0) {
                    filtered_json_data[scope][assetType.KEYWORD].push(el);
                    keywordNumber--;
                }
                break;
            default:
                console.log(el.text_type + ' : Unexpected assets type');
        }
    }

    let minTitleNumber = headlineHandler.minInputs;
    let minLongTitleNumber = longHeadlineHandler.minInputs;
    let minDescriptionNumber = descriptionHandler.minInputs;
    let minCalloutNumber = calloutHandler.minInputs;
    let minKeywordNumber = keywordHandler.minInputs;

    for (const key in filtered_json_data[scope]) {
        const dataList = filtered_json_data[scope][key];
        switch (key) {
            case assetType.HEADLINE:
                if (dataList.length < minTitleNumber) {
                    const assetsDeficit = minTitleNumber - dataList.length;
                    filtered_json_data[scope][key] = [...dataList, ...generateNewInputObj(assetsDeficit, scope, siteId, key)];
                }
                break;
            case assetType.LONG_HEADLINE:
                if (dataList.length < minLongTitleNumber) {
                    const assetsDeficit = minLongTitleNumber - dataList.length;
                    filtered_json_data[scope][key] = [...dataList, ...generateNewInputObj(assetsDeficit, scope, siteId, key)];
                }
                break;
            case assetType.DESCRIPTION:
                if (dataList.length < minDescriptionNumber) {
                    const assetsDeficit = minDescriptionNumber - dataList.length;
                    filtered_json_data[scope][key] = [...dataList, ...generateNewInputObj(assetsDeficit, scope, siteId, key)];
                }
                break;
            case assetType.CALLOUT:
                if (dataList.length < minCalloutNumber) {
                    const assetsDeficit = minCalloutNumber - dataList.length;
                    filtered_json_data[scope][key] = [...dataList, ...generateNewInputObj(assetsDeficit, scope, siteId, key)];
                }
                break;
            case assetType.KEYWORD:
                if (dataList.length < minKeywordNumber) {
                    const assetsDeficit = minKeywordNumber - dataList.length;
                    filtered_json_data[scope][key] = [...dataList, ...generateNewInputObj(assetsDeficit, scope, siteId, key)];
                }
                break;
            default:
                console.log(dataList + ' : Unexpected assets type');
        }
    }

    return filtered_json_data;
}
export const getSiteAssets = (user, site_id, callback_success, callback_error) => {
    backendFetch({path: API_ASSETS_LIST, user, queryParams: {site_id}})
        .then(json_data => {
            callback_success(filteringData(json_data, site_id));
        }).catch(error => {
            console.log(error);
            if (callback_error) {
                callback_error(error);
            }
    });
}