/*
*  doc: functionality similar to 'AzaCountrySelector' https://azameo.atlassian.net/wiki/spaces/D/pages/2102984738/AzaCountrySelector
 */

import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

import {useFormat} from "../../app/globalHooks";
import {languages} from "../../utils/languages";
import {AzaBox} from "../mui/AzaBox";
import {AzaTextFieldBasic} from "../mui/AzaTextField";
import {AzaAutocomplete, azaCreateFilterOptions} from "../mui/AzaAutocomplete";

const filter = azaCreateFilterOptions();

export const AzaLanguageSelector = ({
                                       language_code,
                                       disabled,
                                       loading,
                                       onChange,
                                       whitelist,
                                       blacklist,
                                       allchoice,
                                       required = false,
                                       multiple = false,
                                       min,
                                       max,
                                       errorFromSlice = false
                                   }) => {
    const {t} = useTranslation();
    const {formatLanguage} = useFormat();

    const [errorEmpty, setErrorEmpty] = useState(errorFromSlice);
    const [errorUnderMin, setErrorUnderMin] = useState(false);
    const [errorOverMax, setErrorOverMax] = useState(false);
    const [errorNotFound, setErrorNotFound] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const handleInput = (event, value) => {
        if (required) {
            if (!multiple) {
                setErrorEmpty(!value || value.length === 0);
            }
        }
        setInputValue(value)
    };

    useEffect(() => {
        setInputValue("")
    }, [multiple])

    const handleChange = useCallback((event, value) => {
        if (multiple) {
            if (required) {
                setErrorEmpty(value.length === 0);
            }
            if (min && min > 0) {
                setErrorUnderMin(value.length < min);
            }
            if (max && max > 0) {
                setErrorOverMax(value.length > max);
            }
            if (value?.find(elem => elem.code === 'all')) {
                onChange(['all'])
            } else {
                onChange?.(value.map(elem => elem.code));
            }
        } else {
            if (required) {
                setErrorEmpty(!(value?.code && value.code !== ""));
            }
            onChange?.(value?.code);
        }
    }, [multiple, onChange, required, min, max]);


    const error = useMemo(() => {
        return errorEmpty || errorNotFound || errorUnderMin || errorOverMax;
    }, [errorEmpty, errorNotFound, errorUnderMin, errorOverMax]);

    const defaultLanguages = useMemo(() => {
        if (allchoice) {
            return [{code: "all", label: t('component.aza-language-selector.all-language')}];
        } else {
            return [];
        }
    }, [allchoice, t]);

    const languages_list = useMemo(() => {
        return defaultLanguages.concat(languages.filter(
            (language) => {
                return (!whitelist || whitelist.length === 0 || whitelist.includes(language.code))
            }
        ).filter(
            (language) => {
                return (!blacklist || blacklist.length === 0 || !blacklist.includes(language.code))
            }
        ).map((language) => {
            return {
                code: language.code,
                label: formatLanguage(language.code),
            }
        }))
    }, [blacklist, whitelist, defaultLanguages, formatLanguage]);

    const languageValue = useMemo(() => {
        if (multiple) {
            return languages_list.filter((language) => {
                return language_code?.includes(language.code);
            });
        }
        return languages_list.find((language) => {
            return language.code === language_code;
        }) ?? {code: "", label: ""}
    }, [language_code, languages_list, multiple]);
    return (
        <AzaBox sx={{position: 'relative'}}>
            <AzaAutocomplete
                disablePortal
                options={languages_list}
                value={languageValue}
                inputValue={inputValue}
                disabled={disabled}
                onChange={handleChange}
                onInputChange={handleInput}
                loading={loading}
                autoHighlight
                isOptionEqualToValue={(option, value) => {
                    if (value?.code.length < 1) {
                        return true;
                    }
                    return option.code === value?.code;
                }}
                getOptionLabel={(option) =>  option.label ?? ""}
                renderOption={(props, option) =>  (
                    <AzaBox component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        {option.label}
                    </AzaBox>
                )}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (filtered.length === 0) {
                        setErrorNotFound(true);
                    } else {
                        if (errorNotFound) {
                            setErrorNotFound(false);
                        }
                    }
                    return filtered;
                }}
                renderInput={(params) => (
                    <AzaTextFieldBasic
                        {...params}
                        required={true}
                        error={error}
                        label={t('component.aza-language-selector.language', {count: 2})}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </AzaBox>
    )
}
