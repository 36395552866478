import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useMemo} from "react";
import {RDB_USER, SUPPORTED_LANGUAGES, SUPPORTED_LANGUAGES_NAMES} from "../../utils/constant";
import {FormControl, InputAdornment, MenuItem} from "@mui/material";
import {AzaSelect} from "../../components/mui/AzaSelect";
import {Language} from "@mui/icons-material";
import {useDbItem} from "../../utils/rdbHooks";
import {useUser} from "../../app/globalHooks";


const useLanguage = () => {
    const {i18n} = useTranslation();
    const {user, loading: loadingUser} = useUser()
    const {item: userDB, loading: loadingDb, updateField} = useDbItem(RDB_USER, user?.uid)

    const changeLanguage = useCallback((lng) => {
        if (user) {
            updateField("lng", lng)
        }
        i18n.changeLanguage(lng).then(() => {
        });
    }, [i18n, updateField, user]);

    useEffect(() => {
        if (loadingUser || loadingDb || user === null) return;
        if (userDB?.lng === undefined) {
            changeLanguage(i18n.resolvedLanguage);
        } else {
            if (userDB?.lng !== i18n.resolvedLanguage) {
                i18n.changeLanguage(userDB?.lng).then(() => {
                });
            }
        }
    }, [changeLanguage, i18n, loadingDb, loadingUser, user, userDB?.lng])

    const language = useMemo(() => {
        if (user && !loadingDb && userDB) {
            return userDB.lng;
        }
        return i18n.resolvedLanguage
    }, [user, loadingDb, i18n, userDB]);

    return {language, changeLanguage};
}

export const LanguageSelector = ({sx, large, withIcon=true}) => {
    const {language, changeLanguage} = useLanguage();

    const changeUserLanguage = useCallback((e) => {
        changeLanguage(e.target.value);
    }, [changeLanguage])


    const getLanguagesItems = () => {
        return SUPPORTED_LANGUAGES.map((lang) => {
            return (
                <MenuItem key={lang} value={lang}>{SUPPORTED_LANGUAGES_NAMES[lang]}</MenuItem>
            )
        });
    };

    return (
        <FormControl
            sx={{width: "auto", ...sx}}
            variant="standard">
            <AzaSelect
                value={language}
                disableUnderline
                onChange={changeUserLanguage}
                renderValue={(value) => {
                    if (large) {
                        return SUPPORTED_LANGUAGES_NAMES[value];
                    } else {
                        return value;
                    }
                }
                }
                startAdornment={
                    (withIcon&&<InputAdornment position={"start"}><Language/></InputAdornment>)
                }
            >
                {getLanguagesItems()}
            </AzaSelect>
        </FormControl>
    )
}
