import {createSlice} from "@reduxjs/toolkit";
import {MIN_BUDGET_AMOUNT, MIN_TARGET_ROI} from "../tempHardcodedConst";

const createNewCampaignSliceName = "createNewCampaignData";

const filerData = (data) => {
    const assetsData = [];
    for(let el of data) {
        assetsData.push(el.cleaned_text);
    }
    return assetsData;
}
export const createNewCampaignSlice = createSlice({
    name: `${createNewCampaignSliceName}`,
    initialState: {
        campaign_params: {
            name: "",
            language: "",
            countries: [],
            campaign_type: "PerfMax",
            network: ["GoogleAds"],
            budget: {
                daily: 5,
            },
            site: {
                business_name: '',
                headlines: [],
                long_headlines: [],
                descriptions: [],
                callout: [],
                keywords: {
                    keywords: []
                },
            },
        },
        site_id_change_alert: true,
        // additionally updates in 'StepByStep.js' when site_id change
        assetScope: {
            activeTab: 0,
            scopeName: 'Site'
        },
        checkerSiteId: '',
        assets_loaded: false,
        assets_descriptions_loaded: false,
        assets_was_loaded: false,
        on_loading: false,
        errors_counter: {
            0: {
                site: 0,
                product: 0
            },
            1: 0,
            2: 0,
            3: 0
        },
        ad_assets: {
            brand_name: {
                name: '',
                error: {
                    status: false,
                    message: ''
                }
            },
            headlines: {
                quantity: 3,
                data: []
            },
            long_headlines: {
                quantity: 1,
                data: []
            },
            descriptions: {
                quantity: 1,
                data: []
            },
            callout: {
                quantity: 3,
                data: []
            },
            keywords: {
                quantity: 15,
                data: []
            },
            product_assets: {},
            product_asset_selected: {},
            option_selected: null,
            product_info_selected: {
                prodId: '',
                prodName: ''
            },
            list_products: [],
            images: {
                error: {
                  status: false,
                  message: '',
                },
                files: [],
            },
            logo: {
                error: {
                    status: false,
                    message: '',
                },
                files: [],
            },

        },
        audience: {
            audience_targeting: {
                website_visitor: true,
                audience_expansion: false
            },
            // KEYWORDS block is in "ad_assets"
            geo_targeting: {
                // Should be careful with init value => if multiple=true: [] (array); if not: string
                // Think, maybe find other solution
                countries: {
                    data: [],
                    error: {
                        status: false,
                        message: '',
                    }
                },
                // language (?)
                languages: {
                    data: '',
                    error: {
                        status: false,
                        message: '',
                    }
                },
            }
        },
        networks: {
            at_least_one_selected: true,
            azameo_partners: {
                is_selected: true,
                network_types: {
                    retargeting: false,
                    display_rtb: false,
                    native: false,
                }
            },
            google_ads: {
                is_selected: true,
                network_types: {
                    search: false,
                    display: false,
                    shopping: false,
                    partner: false,
                }
            },
            microsoft_ads: {
                is_selected: true,
                network_types: {
                    search: false,
                    display: false,
                    shopping: false,
                    partner: false,
                }
            }
        },
        settings: {
            all_campaigns_names: [],
            campaign_name: {
                new_name: '',
                error: {
                    status: false,
                    message: ''
                },
            },
            daily_budget_data: {
                daily_budget: MIN_BUDGET_AMOUNT,
                error: {status: false, massage: ''}
            },
            exceed_budget_checked: false,
            exceed_daily_budget_data: {
                exceed_daily_budget: MIN_TARGET_ROI,
                error: {status: false, message: ''}
            }
        }
    },
    reducers: {
        // to see if ok to replace 'actionSetHeadlines', '...Descriptions...'...
        // or create two function:
        // one : actionUpdateAssets
        // two : actionSetAssets

        // ALERT CHANGE SITE ID (?for admin only?)
        actionSetSiteIdChangeAlert(state, action) {
            state.site_id_change_alert = action.payload;
        },

        // ERRORS COUNTER
        actionSetErrorCounter(state, action) {
            const data = action.payload;
            if(data[0]) {
                state.errors_counter[0] = {...state.errors_counter[0], ...data[0]};
            } else {
                state.errors_counter = {...state.errors_counter, ...data};
            }
        },

    // **** AD ASSET GROUP ****
        // GENERATED ASSETS PRODUCT / SITE (ON BTN CLICK 'GENERATE')
        actionSetNewGeneratedAssets(state, action) {
            const data = action.payload;
            const newData = data.data;
            const assetScope = data.scope;
            if(assetScope === 'Product') {
                state.ad_assets.product_asset_selected = newData[state.ad_assets.product_info_selected.prodId];
                state.ad_assets.product_assets = {...state.ad_assets.product_assets, ...newData};
            } else {
                state.ad_assets.headlines.data = newData.Site['Headline'];
                state.ad_assets.long_headlines.data = newData.Site['Long Headline'];
                state.ad_assets.descriptions.data = newData.Site['Description'];
                state.ad_assets.callout.data = newData.Site['Callout'];
                state.ad_assets.keywords.data = newData.Site['Keyword'];

                state.campaign_params.site.headlines = filerData(newData.Site['Headline']);
                state.campaign_params.site.long_headlines = filerData(newData.Site['Long Headline']);
                state.campaign_params.site.descriptions = filerData(newData.Site['Description']);
                state.campaign_params.site.callout = filerData(newData.Site['Callout']);
                state.campaign_params.site.keywords.keywords = filerData(newData.Site['Keyword']);
            }
        },

        // SWITCH ASSET SCOPE ('SITE' VS 'PRODUCT')
        actionUpdateAssetScope(state, action) {
            state.assetScope = action.payload;
        },

        actionExposedUpdateAssetScope(state, action) {
            const scope = action.payload;
            switch (scope) {
                case "Product":
                    state.assetScope = {
                        activeTab: 1,
                        scopeName: 'Product'
                    }
                    break
                case "Brand":
                default:
                    state.assetScope = {
                        activeTab: 0,
                        scopeName: 'Site'
                    }
                    break;
            }
        },

        actionUpdateAssets(state, action) {
            const data = action.payload;
            const helper = data[0];
            let assetType;
            if(helper.text_type.toLowerCase() !== 'callout') {
                assetType = helper.text_type.toLowerCase().replace(" ", "_") + 's';
            } else {
                assetType = helper.text_type.toLowerCase();
            }
            const assetScope = data[0].scope;
            if(assetScope === 'Product') {
                state.ad_assets.product_asset_selected[helper.text_type] = data;
                state.ad_assets.product_assets[helper.product_id][helper.text_type] = data;
            } else {
                state.ad_assets[assetType].data = data;
                if(assetType === 'keywords') {
                    state.campaign_params.site[assetType].keywords = filerData(data);
                } else {
                    state.campaign_params.site[assetType] = filerData(data);
                }
            }
        },

        // SET/REMOVE CIRCULAR PROGRESS ICON (BOOLEAN)
        actionSetAssetsLoaded(state, action) {
            state.assets_loaded = action.payload;
        },

        // ASSETS WAS GENERATED - TRUE/FALSE
        actionSetAssetsWasLoaded(state, action) {
            state.assets_was_loaded = action.payload;
        },

        // ****SITE ASSETS GROUP****
        // SET BRAND NAME
        actionSetAssetsBrandName(state, action) {
            const dataObj = action.payload;
            state.ad_assets.brand_name = {name: dataObj.data, error: dataObj?.error};
            state.campaign_params.site.business_name = dataObj.data;
        },

        // SET HEADLINES
        actionSetHeadlines(state, action) {
            const data = action.payload;
            const newData = data.data;
            const assetScope = data.scope;
            if(assetScope === 'Product') {
                // HARDCODED
                state.ad_assets.product_assets[data.productId]['Headline'] = newData;
                state.ad_assets.product_asset_selected['Headline'] = newData;
            } else {
                state.ad_assets.headlines.data = newData;
                state.campaign_params.site.headlines = filerData(newData);
            }
        },

        // SET LONG HEADLINES
        actionSetLongHeadlines(state, action) {
            const data = action.payload;
            const newData = data.data;
            const assetScope = data.scope;
            if(assetScope === 'Product') {
                // HARDCODED
                state.ad_assets.product_assets[data.productId]['Long Headline'] = newData;
                state.ad_assets.product_asset_selected['Long Headline'] = newData;
            } else {
                state.ad_assets.long_headlines.data = newData;
                state.campaign_params.site.long_headlines = filerData(newData);
            }
        },

        // SET DESCRIPTIONS
        actionSetDescriptions(state, action) {
            const data = action.payload;
            const newData = data.data;
            const assetScope = data.scope;
            if(assetScope === 'Product') {
                // HARDCODED
                state.ad_assets.product_assets[data.productId]['Description'] = newData;
                state.ad_assets.product_asset_selected['Description'] = newData;
            } else {
                state.ad_assets.descriptions.data = newData;
                state.campaign_params.site.descriptions = filerData(newData);
            }
        },

        // SET CALLOUTS
        actionSetCallouts(state, action) {
            const data = action.payload;
            const newData = data.data;
            const assetScope = data.scope;
            if(assetScope === 'Product') {
                // HARDCODED
                state.ad_assets.product_assets[data.productId]['Callout'] = newData;
                state.ad_assets.product_asset_selected['Callout'] = newData;
            } else {
                state.ad_assets.callout.data = newData;
                state.campaign_params.site.callout = filerData(newData);
            }
        },
        // ****SITE ASSETS GROUP END****

        // ****PRODUCT GROUP****
        actionSetProductAssetSelected(state, action) {
            state.ad_assets.product_asset_selected = action.payload;
        },

        actionSetProductOptionSelected(state, action) {
            state.ad_assets.option_selected = action.payload;
        },

        // SET PRODUCTS LIST
        actionSetListProducts(state, action) {
            state.ad_assets.list_products = action.payload;
        },

        // SET PRODUCT ASSETS (PRODUCT_ID: {HEADLINE..., DESCRIPTION..., ...}
        actionSetProductAssets(state, action) {
            state.ad_assets.product_assets = action.payload;
        },

        // SET ID OF SELECTED PRODUCT
        actionSetProductInfoSelected(state, action) {
            state.ad_assets.product_info_selected = action.payload;
        },
        // ****PRODUCT GROUP END****

        // ****IMAGES WORKFLOW*****
        // SET LOGO
        actionSetLogo(state, action) {
            const data = action.payload;
            state.ad_assets.logo.files = [...state.ad_assets.logo.files, ...data.data];
            state.ad_assets.logo.error = data.error;
        },
        // REMOVE LOGO
        actionRemoveLogo(state, action) {
            const logoData = action.payload;
            state.ad_assets.logo.files = state.ad_assets.logo.files.filter((file) => {
                return  file !== logoData;
            })
        },

        // SET IMG
        actionSetImages(state, action) {
            const data = action.payload;
            state.ad_assets.images.files = [...state.ad_assets.images.files, ...data.data];
            state.ad_assets.images.error = data.error;
        },
        // REMOVE IMG
        actionRemoveImage(state, action) {
            const imgData = action.payload;
            state.ad_assets.images.files = state.ad_assets.images.files.filter((file) => {
                console.log(file, imgData)
                return file !== imgData;
            })
        },

        // REMOVE ALL IMG WHEN SITE ID CHANGE
        actionRemoveAllPictures(state){
            state.ad_assets.images = {
                error: {status: false, message: ''},
                files: []
            };
        },
        // ****IMAGES WORKFLOW END*****

        // SET CHECKER SITE ID
        actionSetCheckerSiteId(state, action) {
            state.checkerSiteId = action.payload;
        },

        // SET ON LOADING TRUE / FALSE
        actionSetOnLoadingState(state, action) {
            state.on_loading = action.payload;
        },
    // **** AD ASSET GROUP END ****

        // **** AUDIENCE GROUP ****
        // SET AUDIENCE TARGET
        actionUpdateAudienceTarget(state, action) {
            const data = action.payload;
            let helper = data.name;
            state.audience.audience_targeting[helper] = data.checked;
        },

        // SET KEYWORDS
        actionSetKeywords(state, action) {
            const data = action.payload;
            const newData = data.data;
            const assetScope = data.scope;
            if(assetScope === 'Product') {
                // HARDCODED
                state.ad_assets.product_assets[data.productId]['Keyword'] = newData;
                state.ad_assets.product_asset_selected['Keyword'] = newData;
            } else {
                state.ad_assets.keywords.data = newData;
                state.campaign_params.site.keywords.keywords = filerData(newData);
            }
        },

        // SET COUNTRIES
        actionSetCountries(state, action) {
            const newState = action.payload;
            state.audience.geo_targeting.countries.data = newState.data;
            state.audience.geo_targeting.countries.error = newState?.error;
            state.campaign_params.countries = newState.data;
        },

        // SET LANGUAGES
        actionSetLanguages(state, action) {
            const newState = action.payload;
            state.audience.geo_targeting.languages.data = newState.data;
            state.audience.geo_targeting.languages.error = newState?.error;
            state.campaign_params.language = newState.data;
        },
        // **** AUDIENCE GROUP END ****

        // **** AD NETWORKS ****
        actionSetIsNetworkSelected(state, action) {
            state.networks.at_least_one_selected = action.payload;
        },

        actionUpdateCampaignType(state, action) {
            const data = action.payload;
            const campaignType = data.name;
            if(data.is_selected) {
                state.networks.at_least_one_selected = true;
            }
            state.networks[campaignType].is_selected = data.is_selected;
            // temp solution
            state.campaign_params.campaign_type = "PerfMax";
            state.campaign_params.network = ["GoogleAds"];
        },

        actionUpdateNetworks(state, action) {
            const data = action.payload;
            const campaignType = data.name;
            const networkType = data.network_types;

            if (!(state.networks[campaignType])){
                state.networks[campaignType] = {}
            }

            state.networks[campaignType].network_types = {...state.networks[campaignType]?.network_types??{}, ...networkType};
        },
        // **** AD NETWORKS END ****

        // **** SETTINGS ****
        // SET ALL NAMES OF CAMPAIGNS
        actionSetAllCampaignNames(state, action) {
            state.settings.all_campaigns_names = action.payload;
        },

        // SET CAMPAIGN NAME
        actionSetCampaignName(state, action) {
            const data = action.payload
            state.settings.campaign_name = data;
            state.campaign_params.name = data.new_name;
        },

        // SET DAILY BUDGET
        actionSetDailyBudget(state, action) {
            const data = action.payload
            state.settings.daily_budget_data = data;
            state.campaign_params.budget.daily = data.daily_budget;
        },

        // SET CHECKED EXCEED DAILY BUDGET (TRUE / FALSE)
        actionSetCheckedExceedBudget(state, action) {
            state.settings.exceed_budget_checked = action.payload;
        },

        // SET EXCEED DAILY BUDGET (%)
        actionSetExceedDailyBudget(state, action) {
            state.settings.exceed_daily_budget_data = action.payload;
        },
        // **** SETTINGS END ****
    }
});

export const selectSiteIdChangeAlert = (state) => {
    return state[`${createNewCampaignSliceName}`].site_id_change_alert;
}
export const selectErrorsCounter = (state) => {
    return state[`${createNewCampaignSliceName}`].errors_counter;
}

export const selectAssetScope = (state) => {
    return state[`${createNewCampaignSliceName}`].assetScope;
}

// SELECT PRODUCTS LIST
export const selectListProducts = (state) => {
    return state[`${createNewCampaignSliceName}`].ad_assets.list_products;
}

// SELECT PRODUCTS WITH ASSETS (HEADLINE, DESCRIPTION)
export const selectProductAssets = (state) => {
    return state[`${createNewCampaignSliceName}`].ad_assets.product_assets;
}

// SELECT PRODUCTS WITH ASSETS (HEADLINE, DESCRIPTION)
export const selectProductAssetSelected = (state) => {
    return state[`${createNewCampaignSliceName}`].ad_assets.product_asset_selected;
}

// SELECT ACTIVE OPTION FOR AUTOCOMPLETE
export const selectProductOptionSelected = (state) => {
    return state[`${createNewCampaignSliceName}`].ad_assets.option_selected;
}

// SET ID OF SELECTED PRODUCT
export const selectProductInfoSelected = (state) => {
    return state[`${createNewCampaignSliceName}`].ad_assets.product_info_selected;
}

export const selectAssetsLoaded = (state) => {
    return state[`${createNewCampaignSliceName}`].assets_loaded;
}

export const selectAssetsWasLoaded = (state) => {
    return state[`${createNewCampaignSliceName}`].assets_was_loaded;
}

export const selectBrandName = (state) => {
    return state[`${createNewCampaignSliceName}`].ad_assets.brand_name;
}

export const selectHeadlines = (state) => {
    return state[`${createNewCampaignSliceName}`].ad_assets.headlines.data;
}

export const selectLongHeadlines = (state) => {
    return state[`${createNewCampaignSliceName}`].ad_assets.long_headlines.data;
}

export const selectDescriptions = (state) => {
    return state[`${createNewCampaignSliceName}`].ad_assets.descriptions.data;
}

export const selectCallouts = (state) => {
    return state[`${createNewCampaignSliceName}`].ad_assets.callout.data;
}

export const selectLogo = (state) => {
    return state[`${createNewCampaignSliceName}`].ad_assets.logo;
}

export const selectImages = (state) => {
    return state[`${createNewCampaignSliceName}`].ad_assets.images;
}

export const selectCheckerSiteId = (state) => {
    return state[`${createNewCampaignSliceName}`].checkerSiteId;
}

export const selectOnLoadingState = (state) => {
    return state[`${createNewCampaignSliceName}`].on_loading;
}

export const selectAudienceTarget = (state) => {
    return state[`${createNewCampaignSliceName}`].audience.audience_targeting;
}

export const selectKeywords = (state) => {
    return state[`${createNewCampaignSliceName}`].ad_assets.keywords.data;
}

export const selectCountries = (state) => {
    return state[`${createNewCampaignSliceName}`].audience.geo_targeting.countries;
}

export const selectLanguages = (state) => {
    return state[`${createNewCampaignSliceName}`].audience.geo_targeting.languages;
}

// IS NETWORK SELECTED
export const selectIsNetworkSelected = (state) => {
    return state[`${createNewCampaignSliceName}`].networks.at_least_one_selected;
}

// SELECT NETWORK AZAMEO
export const selectNetworkAzameo = (state) => {
    return state[`${createNewCampaignSliceName}`].networks.azameo_partners;
}

// SELECT NETWORK GOOGLE
export const selectNetworkGoogle = (state) => {
    return state[`${createNewCampaignSliceName}`].networks.google_ads;
}

// SELECT NETWORK MICROSOFT
export const selectNetworkMicrosoft = (state) => {
    return state[`${createNewCampaignSliceName}`].networks.microsoft_ads;
}

// SELECT ALL CAMPAIGNS NAMES
export const selectAllCampaignsNames = (state) => {
    return state[`${createNewCampaignSliceName}`].settings.all_campaigns_names;
}
// SELECT CAMPAIGN NAME
export const selectCampaignName = (state) => {
    return state[`${createNewCampaignSliceName}`].settings.campaign_name;
}

// SELECT DAILY BUDGET (CURRENCY)
export const selectDailyBudget = (state) => {
    return state[`${createNewCampaignSliceName}`].settings.daily_budget_data;
}

// SELECT CHECKED EXCEED BUDGET (TRUE / FALSE)
export const selectCheckedExceedBudget = (state) => {
    return state[`${createNewCampaignSliceName}`].settings.exceed_budget_checked;
}

// SELECT EXCEED DAILY BUDGET (%)
export const selectExceedDailyBudget = (state) => {
    return state[`${createNewCampaignSliceName}`].settings.exceed_daily_budget_data;
}

export const selectCampaignParams = (state) => {
    return state[`${createNewCampaignSliceName}`].campaign_params;
}

const {actions} = createNewCampaignSlice;

export const {
    actionSetSiteIdChangeAlert,
    actionSetAssetsLoaded,
    actionSetAssetsWasLoaded,
    actionSetHeadlines,
    actionSetLongHeadlines,
    actionSetDescriptions,
    actionSetCallouts,
    actionSetLogo,
    actionRemoveLogo,
    actionSetImages,
    actionRemoveImage,
    actionRemoveAllPictures,
    actionUpdateAssets,
    actionSetCheckerSiteId,
    actionSetOnLoadingState,
    actionUpdateAudienceTarget,
    actionSetKeywords,
    actionSetCountries,
    actionSetLanguages,
    actionUpdateCampaignType,
    actionUpdateNetworks,
    actionSetAllCampaignNames,
    actionSetCampaignName,
    actionSetDailyBudget,
    actionSetCheckedExceedBudget,
    actionSetExceedDailyBudget,
    actionUpdateAssetScope,
    actionExposedUpdateAssetScope,
    actionSetListProducts,
    actionSetProductAssets,
    actionSetNewGeneratedAssets,
    actionSetProductAssetSelected,
    actionSetProductInfoSelected,
    actionSetProductOptionSelected,
    actionSetAssetsBrandName,
    actionSetIsNetworkSelected,
    actionSetErrorCounter,
} = actions;

