/*
Generate New Input Object for Products
 */
const generateTemplate = (idGenerated, scope, siteId, textType, productId, language) => {
    return  {
        cleaned_text: "",
        enabled: true,
        id: idGenerated,
        language: language,
        origin: "Customer",
        product_id: productId,
        scope: scope,
        site_id: siteId,
        text: "",
        text_type: textType,
    };
}

export const generateNewInputObj = (requiredMin, scope, siteId, textType, productId = null, language = 'fr') => {
    const assetsArr = [];
    for (let i = 0; i < requiredMin; i++) {
        let idGenerated = Date.now() + '-' + Math.floor(Math.random() * 1000);
        assetsArr.push(generateTemplate(idGenerated, scope, siteId, textType, productId, language));
    }

    return assetsArr;
};