import {AzaGridContainer, AzaGridItem} from "../../components/mui/AzaGrid";
import {AzaTypography} from "../../components/mui/AzaTypography";
import {AzaStack} from "../../components/mui/AzaStack";
import React, {useCallback, useState} from "react";
import {AzaDivider} from "../../components/mui/AzaDivider";
import {useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useUser} from "../../app/globalHooks";
import {AzaButton} from "../../components/mui/AzaButton";
import {backendFetchPromise, methodType} from "../../utils/backendHelper";
import {API_POST_ADD_SITE} from "../../utils/constant";
import {setSiteId} from "../../app/globalSlice";
import {useDispatch} from "react-redux";
import {AzaAlert} from "../../components/mui/AzaAlert";
import {AzaTextField} from "../../components/mui/AzaTextField";

export const AddSite = ({disabled, onDone}) => {

    const {user, loading: loadingUser} = useUser()
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [url, setUrl] = useState("")
    const [isSuccess, setIsSuccess] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState(false);

    const enterSite = useCallback(() => {
        setLoading(true);
        const data = {
            website_url: url
        }
        backendFetchPromise({user, path: API_POST_ADD_SITE, method: methodType.POST, data})
            .then(resp => resp.json())
            .then((json) => {
                if (json.code) {
                    setHasError(true);
                    switch (json.code) {
                        case 2:
                            // Bad formateed
                            setErrorMessage(t("chat.messages.badurl.text"));
                            break;
                        case 4:
                        case 5:
                        case 6:
                            // Duplicate
                            setErrorMessage(t("chat.messages.duplicateurl.text"));
                            break;
                        case 7:
                            // Forbidden
                            setErrorMessage(t("chat.messages.badsite.text"));
                            break;
                        case 9:
                        default:
                            // Generic:
                            setErrorMessage(t("chat.messages.urlerror.text"));
                            break;
                    }
                } else {
                    dispatch(setSiteId(json.id));
                    setIsSuccess(true);
                    if (onDone) {
                        onDone();
                    }
                }
            }).finally(()=>{
                setLoading(false);
        })
    }, [dispatch, user, t, url, onDone])

    return (<>
            <AzaStack alignItems={"flex-start"}>
                <AzaGridItem>
                    <AzaTypography variant={"body"}>{t("addsite.description")} </AzaTypography>
                </AzaGridItem>
                <AzaGridItem>
                    <AzaTypography variant={"body"}>{t("addsite.info")}</AzaTypography>
                </AzaGridItem>
            </AzaStack>
            <AzaDivider sx={{borderColor: theme.palette.secondary.main}}/>
            <br/>
            <AzaStack alignItems={"stretch"} >
                <AzaGridItem  >
                    <AzaTextField
                        width={1}
                        disabled={disabled || loading}
                        value={url}
                        onChange={(e) => {
                            setHasError(false);
                            setUrl(e.target.value)
                        }}
                        placeholder={t("addsite.addurl")}
                        variant="outlined"
                        style={{backgroundColor: theme.palette.background.paper}}
                    />
                    {hasError && <AzaAlert severity="error">
                        <AzaTypography>{errorMessage}</AzaTypography>
                    </AzaAlert>}
                    {isSuccess && <AzaAlert severity="success">
                        <AzaTypography>{t("chat.messages.addsitesuccess.text")}</AzaTypography>
                    </AzaAlert>}
                </AzaGridItem>
            </AzaStack>
            <br/>
            <AzaGridContainer direction={"row"}>
                <AzaGridItem xs>
                </AzaGridItem>
                <AzaGridItem>
                    <AzaButton
                        onClick={enterSite}
                        variant={"contained"}
                        disabled={disabled  || loading || loadingUser}
                    >{t("addsite.button")}
                    </AzaButton>
                </AzaGridItem>
            </AzaGridContainer>
        </>)
}
