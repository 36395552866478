import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

import {AzaChoice} from "../../components/azameo/AzaChoice";
import {AzaButton} from "../../components/mui/AzaButton";
import {AzaGrid} from "../../components/mui/AzaGrid";
import {AzaInputAdornment} from "../../components/mui/AzaInputAdornment";
import {AzaMenu, AzaMenuItem, AzaMenuItemHeader, AzaMenuList} from "../../components/mui/AzaMenu";
import {AzaTextField} from "../../components/mui/AzaTextField";
import {AzaTooltip} from "../../components/mui/AzaTooltip";
import {badgeColor} from "./utils";
import {RDB_SITE} from "../../utils/constant";
import {useConnected, useDbItem, useUserRDB} from "../../utils/rdbHooks";

// icons
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircleIcon from "@mui/icons-material/Circle";
import FavoriteIcon from '@mui/icons-material/Favorite';
import LinkIcon from '@mui/icons-material/Link';
import SearchIcon from '@mui/icons-material/Search';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {AzaFilter} from "../../components/mui/AzaIcons";
import {ThemeCustomization} from "../../themes";
import {useTheme} from "@mui/material";

//  Some style constants
const selectorWidth = 500;
const nameLength = 25
const maxNumberSearchResult = 15
// utils functions




const shortenName = (name, nameLength) => {
    return name?.length > nameLength ? name.substring(0, nameLength) + "..." : name
}

const SiteFavorite = ({site, toggleFavorite}) => {

    // Check if the site is in the favorites list
    const isFavorite = useMemo(() => (!!(site?.favorite)), [site])
    // Choose the color of the favorite icon
    const favoriteColor = useMemo(() => (isFavorite ? "red" : "gray"), [isFavorite])

    return (
        <FavoriteIcon
            fontSize="small"
            style={{color: favoriteColor}}
            onClick={(event) => {
                toggleFavorite(site.site_id)
                event.stopPropagation()
            }}
        />
    )
}

const AdminSiteMenuItem = ({site, toggleFavorite}) => {
    // Here we should get the status of the site to display a colored badge
    // For now simply get it from the rdb, one connexion per site to avoid reading 10000 sites in admin mode
    const {item: status, loading} = useDbItem(RDB_SITE, `${site.site_id}/status`)
    const {t} = useTranslation()
    // Get the color of the badge from both the loading state and the site status
    const color = useMemo(() => (badgeColor(status, loading)), [loading, status])

    // Shorten the name if too long
    const shortName = useMemo(() => (shortenName(site.name, nameLength)), [site?.name])

    return (
        <AzaGrid
            container
            direction={"row"}
            spacing={1}
            justifyContent="flex-start"
            alignItems="center"
        >
            <AzaGrid item>
                {!loading &&
                    <AzaTooltip title={t(`site_selector.${color}`)}>
                        <span><CircleIcon fontSize="small" style={{color: color}}/></span>
                    </AzaTooltip>
                }
            </AzaGrid>
            <AzaGrid item>
                <AzaTooltip title={t("site_selector.favorite")}>
                     <span>
                         <SiteFavorite
                             site={site}
                             toggleFavorite={toggleFavorite}
                         />
                     </span>
                </AzaTooltip>
            </AzaGrid>
            <AzaGrid item>
                {site.site_id}
            </AzaGrid>
            <AzaGrid item>
                {shortName}
            </AzaGrid>
            {!!(site?.connected) &&
                <AzaGrid item>
                    <AzaTooltip title={t("site_selector.connected")}>
                        <span><VisibilityIcon/></span>
                    </AzaTooltip>
                </AzaGrid>
            }
            {!!(site?.same_customer) &&
                <AzaGrid item>
                    <AzaTooltip title={t("site_selector.same_customer")}>
                        <span><LinkIcon/></span>
                    </AzaTooltip>
                </AzaGrid>
            }
            {!!(site?.created_today) &&
                <AzaGrid item>
                    <AzaTooltip title={t("site_selector.created_today")}>
                        <span><TimelapseIcon/></span>
                    </AzaTooltip>
                </AzaGrid>
            }
        </AzaGrid>
    )
}


const AdminSiteSelectorButton = (
    {
        site, toggleMenu, toggleFavorite
    }
) => {
    /*
    // AdminSiteSelectorButton - The Button that displays the current selected site and opens the menu to select another site
     */

    // Here we should get the status of the site to display a colored badge
    // For now simply get it from the rdb, one connexion per site to avoid reading 10000 sites in admin mode
    const {item: status, loading} = useDbItem(RDB_SITE, `${site?.site_id}/status`)

    const {t} = useTranslation()

    // Get the color of the badge from both the loading state and the site status
    const color = useMemo(() => (badgeColor(status, loading)), [loading, status])

    // Shorten the name if too long
    const shortName = useMemo(() => (shortenName(site?.name, nameLength)), [site?.name])

    // The display is a button with the site name and a badge
    // Clicking on the button will open the selector
    return (
        <ThemeCustomization presetColor={"themeColorsAdmin"}>
            <AzaButton
                variant="outlined"
                size={"small"}
                sx={{
                    maxWidth: "100%",
                    width: selectorWidth,
                    textTransform: "unset !important", // This remove the uppercase text transform from the button
                    color: "text.primary", // This remove the blue color from the button
                    fontSize: "1rem", // This make the text size normal
                }}
                onKeyPress={(e) => {e.preventDefault()}}
                onClick={(e) => {
                    toggleMenu(e)
                }}
            >
                <AzaGrid
                    container
                    direction={"row"}
                    spacing={1}
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <AzaGrid item>
                        <AzaTooltip title={t(`site_selector.${color}`)}>
                            <span><CircleIcon fontSize="small" style={{color: color}}/></span>
                        </AzaTooltip>
                    </AzaGrid>
                    <AzaGrid item>
                        <AzaTooltip title={t(`site_selector.favorite`)}>
                            <span>
                                <SiteFavorite
                                    site={site}
                                    toggleFavorite={toggleFavorite}
                                />
                            </span>
                        </AzaTooltip>
                    </AzaGrid>
                    <AzaGrid item>
                        {site.site_id}
                    </AzaGrid>
                    <AzaGrid item sx={{textAlign: "left"}}>
                        {shortName}
                    </AzaGrid>
                    {/* This a space to fill the space and put the array on the right */}
                    <AzaGrid item sx={{flex: 1}}/>
                    {/* The arrow down icon */}
                    <AzaGrid item>
                        <ArrowDropDownIcon fontSize="large" style={{verticalAlign: "middle"}}/>
                    </AzaGrid>
                </AzaGrid>
            </AzaButton>
        </ThemeCustomization>
    )
}


const AdminSiteSelectorList = (
    {
        sites, name, onClick, toggleFavorite, currentPage, numberPages, nextPage, prevPage, highlightfirst=false, defaultSelected=0
    }
) => {
    /*
    // AdminSiteSelectorList - A list of sites under a single category "name", ex: search result, recommended...
     */
    const {t} = useTranslation()
    const theme = useTheme();

    return (
        <>
            {sites.length > 0 && (
                <AzaMenuItem>
                    <b>{t(`site_selector.${name}`)}</b>
                    {numberPages>1 && <> <AzaButton variant={"text"} disabled={currentPage===1} onClick={prevPage}>{t("common.prev")}</AzaButton> {t("common.page")} {currentPage} / {numberPages}  <AzaButton variant={"text"} disabled={currentPage===numberPages} onClick={nextPage}>{t("common.next")}</AzaButton></>}
                </AzaMenuItem>
            )}
            {sites.map((site, idx) =>{
                return (
                <AzaMenuItem
                    key={name + site.site_id}
                    onClick={() => onClick(site.site_id)}
                    style={{backgroundColor: highlightfirst && idx === defaultSelected ? theme.palette.primary.lighter : null}}
                >
                    <AdminSiteMenuItem
                        site={site}
                        toggleFavorite={toggleFavorite}
                    />
                </AzaMenuItem>
            )})}
        </>
    )
}

const SiteSearchField = ({search, setSearch, onSelected, incrSelect, decrSelect, incrPage, decrPage}) => {
    const {t} = useTranslation()

    useEffect(() => {
        setSearch("");
    }, [setSearch])

    // noinspection JSUnusedGlobalSymbols
    return (
        <AzaTextField
            value={search}
            onChange={(e) => {
                setSearch(e.target.value.toLowerCase());
                e.stopPropagation()
            }}
            onKeyDown={(e) => {
                if(e.key==="Enter"){
                    if (onSelected) {
                        onSelected();
                    }
                }
                if(e.key==="ArrowDown"){
                    if (incrSelect) {
                        incrSelect();
                    }
                }
                if(e.key==="ArrowUp"){
                    if (decrSelect) {
                        decrSelect();
                    }
                }
                if(e.key==="PageDown"){
                    if (incrPage) {
                        incrPage();
                    }
                }
                if(e.key==="PageUp"){
                    if (decrPage) {
                        decrPage();
                    }
                }
                e.stopPropagation();
            }}
            label={t("site_selector.search")}
            size={"small"}
            sx={{
                flex: 1,
                m: 0,
            }}
            InputProps={{
                endAdornment: (
                    <AzaInputAdornment position="end">
                        <SearchIcon/>
                    </AzaInputAdornment>
                ),
                inputRef: (input) => {
                    if (input) {
                        input.focus()
                    }}
            }}
        />
    )
}

const AdminSiteSelectorMenu = (
    {
        siteID, siteList, open, anchorEl, closeMenu, changeSite, favorites, toggleFavorite, isSmallScreen
    }
) => {

    const theme = useTheme();
    const [sort,setSort] = useState("site_id");
    const sortSites = useCallback((first, second) => {
        if (isNaN(parseInt(first[sort]))){
            const t1 = (first[sort]+"").toLowerCase();
            const t2 = (second[sort]+"").toLowerCase();
            return t1.localeCompare(t2);
        }else{
            return parseInt(second[sort]) - parseInt(first[sort]);
        }
    },[sort]);
    const [filter, setFilter] = useState({
        "disabled": true,
        "favorite": false,
        "connected": false,
        "same_client": false
    });

    function handleSortChange(newsort) {
        setSort(newsort);
    }

    // The search string
    const [search, setSearch] = useState("")
    const {t} = useTranslation()
    const [numberPages, setNumberPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const nextPage = () => {
        if (currentPage < numberPages) {
            setCurrentPage(currentPage + 1)
        }
    }
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }
    const [defaultSelected, setDefaultSelected] = useState(0);


    // extract only a few sites from the list of sites: recent, connected and search result
    const searchResult = useMemo(() => {
        if (!siteList) return []
        const output = []
        // start with search result, only apply the search filter if more than 2 characters
        if (search && search.length > 2) {
            for (const site of Object.values(siteList)) {
                if (site.site_id.includes(search) || site.name.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").includes(search)) {
                    if (!filter?.disabled) {
                        let first = true
                        if ((filter.connected && site.connected) && first) {
                            first = false;
                            output.push(site);
                        }
                        if ((filter.favorite && site.favorite) && first) {
                            first = false;
                            output.push(site);
                        }
                        if ((filter.same_client && site.same_customer) && first) {
                            first = false;
                            output.push(site);
                        }
                    } else {
                        output.push(site);
                    }
                }
            }
        }
        let sorted = output.sort(sortSites).reverse();

        setNumberPages(Math.ceil(sorted.length/maxNumberSearchResult))
        if (numberPages === 1) {
            setCurrentPage(1)
        }
        if (currentPage > numberPages) {
            setCurrentPage(1)
        }


        // Limit the number of sites to maxNumberSearchResult
        sorted = sorted.slice((currentPage-1)  * maxNumberSearchResult, currentPage * maxNumberSearchResult)
        return sorted
    }, [search, siteList, filter, sortSites, currentPage, setNumberPages, numberPages, setCurrentPage])

    useEffect(() => {
        if (defaultSelected >= searchResult.length) {
            setDefaultSelected(searchResult.length-1)
        }
        if (searchResult.length === 0) {
            setDefaultSelected(0)
        }
    }, [defaultSelected, searchResult.length]);

    const nextSelected = () => {
        if (defaultSelected < searchResult.length-1) {
            setDefaultSelected(defaultSelected + 1)
        } else if (currentPage < numberPages) {
            setDefaultSelected(0);
            nextPage();
        }
    }
    const prevSelected = () => {
        if (defaultSelected > 0) {
            setDefaultSelected(defaultSelected - 1);
        } else if (currentPage > 1) {
            setDefaultSelected(maxNumberSearchResult-1);
            prevPage();
        }
    }
    // Create a list of recommended sites, it's a merge between the recent and favorites sites
    const recommended = useMemo(() => {
        if (!siteList) return []

        // Store in a dictionary for easier deduplication
        const output = {}

        // Add the  sites created today
        const today = new Date()
        // Get the date of today at midnight
        today.setHours(0, 0, 0, 0)
        for (const [id, site] of Object.entries(siteList)) {
            // Add the site if it was created today
            if (site.created_today) {
                output[id] = site
            }
            // Add the site if connected
            if (site.connected) {
                output[id] = site
            }
            // Add the site if it was selected recently
            if (site.recent) {
                output[id] = site
            }
            // Add the site if the same customer
            if (site.same_customer) {
                output[id] = site
            }
            // Add the site if it's a favorite
            if (site.favorite) {
                output[id] = site
            }
        }

        // Make sure to remove the site ID from the current list
        delete output[siteID.toString()];

        const filtered =[];
        for (const site of Object.values(output)) {
            if (!filter?.disabled) {
                let first = true
                if ((filter.connected && site.connected) && first) {
                    first = false;
                    filtered.push(site);
                }
                if ((filter.favorite && site.favorite) && first) {
                    first = false;
                    filtered.push(site);
                }
                if ((filter.same_client && site.same_customer) && first) {
                    first = false;
                    filtered.push(site);
                }
            } else {
                filtered.push(site);
            }
        }
        let sorted = filtered.sort(sortSites)
        // Limit the number of sites to maxNumberSearchResult
        if (sorted.length > maxNumberSearchResult) {
            sorted = sorted.slice(0, maxNumberSearchResult)
        }
        return sorted

    }, [siteID, siteList, filter,sortSites])

    const sortlist = ["site_id", "name"];
    const filterlist = ["favorite", "connected", "same_client"];


    return (
        <AzaMenu
            anchorEl={anchorEl}
            open={open}
            onClose={() => {
                    closeMenu();
                }
             }
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{
                style: {
                    width: selectorWidth,
                },
            }}
        >
            <AzaMenuList>
                <AzaMenuItemHeader
                    divider={true}
                    style={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: theme.palette.background.paper,
                        zIndex: 2}}
                >
                    <SiteSearchField
                        search={search}
                        setSearch={setSearch}
                        onSelected={() => {
                            changeSite(searchResult[defaultSelected].site_id);
                        }}
                        incrPage={nextPage}
                        decrPage={prevPage}
                        incrSelect={nextSelected}
                        decrSelect={prevSelected}

                    />
                    {isSmallScreen
                        ?
                        null
                        : (<>
                            <AzaChoice
                                choice={sort}
                                list={sortlist}
                                handlechoiceChange={handleSortChange}
                                translationSource="site_selector.sortby."
                                name="sorter"
                                icon={<SortByAlphaIcon/>}
                                iconOnly={true}
                            />
                            <AzaChoice
                                choice={filter}
                                list={filterlist}
                                handlechoiceChange={setFilter}
                                multiple={true}
                                translationSource="site_selector.filterby."
                                name="filter"
                                icon={<AzaFilter/>}
                                iconOnly={true}
                            />
                            <AzaButton
                                //onClick={handleAddSiteOpen}
                                sx={{
                                    textTransform: 'none',
                                    color: theme.palette.secondary.main,
                                }}
                            >
                                <AddBoxIcon/>{t("site_selector.add_site")}
                            </AzaButton></>)}
                </AzaMenuItemHeader>
                {isSmallScreen
                    ?
                    (<AzaMenuItem>
                        <AzaChoice
                            choice={sort}
                            list={sortlist}
                            handlechoiceChange={handleSortChange}
                            translationSource="site_selector.sortby."
                            name="sorter"
                            icon={<SortByAlphaIcon/>}
                            iconOnly={true}
                        />
                        <AzaChoice
                            choice={filter}
                            list={filterlist}
                            handlechoiceChange={setFilter}
                            multiple={true}
                            translationSource="site_selector.filterby."
                            name="filter"
                            icon={<AzaFilter/>}
                            iconOnly={true}
                        />
                        <AzaButton
                            sx={{
                                textTransform: 'none',
                                color: 'rgba(0, 0, 0, 0.54)',
                            }}
                        >
                            <AddBoxIcon/>{t("site_selector.add_site")}
                        </AzaButton>
                    </AzaMenuItem>)
                    : null}
                {searchResult.length > 0 && <AdminSiteSelectorList
                    sites={searchResult}
                    name={"search_result"}
                    onClick={changeSite}
                    favorites={favorites}
                    toggleFavorite={toggleFavorite}
                    currentPage={currentPage}
                    numberPages={numberPages}
                    nextPage={nextPage}
                    prevPage={prevPage}
                    highlightfirst={true}
                    defaultSelected={defaultSelected}
                />}
                {searchResult.length === 0 &&
                <AdminSiteSelectorList
                    sites={recommended}
                    name={"recommended"}
                    onClick={changeSite}
                    favorites={favorites}
                    toggleFavorite={toggleFavorite}
                />}
            </AzaMenuList>
        </AzaMenu>
    )
}

export const AdminSiteSelector = (
    {
        siteID, changeSite, siteList, recent, isSmallScreen
    }
) => {
    // The anchor element for the menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    // The open state of the menu, true if the menu is open
    const open = Boolean(anchorEl);
    // The close menu function will be called by the menu
    const closeMenu = useCallback(() => {
        setAnchorEl(null);
    }, []);
    // The toggle menu function will be called by the button
    const toggleMenu = useCallback((event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }, [ anchorEl]);

    // A single action to close the menu and change the site
    const closeAndChangeSite = useCallback((siteID) => {
        closeMenu();
        changeSite(siteID)
    }, [changeSite, closeMenu])

    // Get the list of favorite sites, favorites are only used by admins
    // get it once for all here to avoid multiple rdb call
    const {favorites, toggleFavorite} = useUserRDB()
    // Convert the favorites list into a dictionary
    const favoritesDict = useMemo(() => {
        const dict = {}
        favorites.forEach((siteID) => {
            dict[siteID.toString()] = true
        })
        return dict
    }, [favorites])

    // Get the list of connected sites
    const {connectedSites} = useConnected()

    // We need to augment the site list with all the available information
    const augmentedSiteList = useMemo(() => {
        if (!siteList) return {}
        // Store in a dictionary for easier deduplication
        const output = {}

        // Add the  sites created today
        const today = new Date()
        // Get the date of today at midnight
        today.setHours(0, 0, 0, 0)

        for (let [id, site] of Object.entries(siteList)) {
            // first make sure the site id is a string, because it would become an array index in rdb otherwise
            id = id.toString()
            // create a clean copy of site to be sure
            site = {...site}
            // convert the id to string to be able to make a string search in it
            site["site_id"] = id.toString()
            site["created_today"] = (new Date(site["creation_date"]) > today)
            site["favorite"] = !!favoritesDict[id]
            site["recent"] = false
            site["connected"] = false
            site["same_customer"] = false
            // add the augmented site to the output
            output[id] = site
        }

        // then recently used site
        for (const id of recent) {
            output[id.toString()].recent = true
        }
        // Connected site
        for (const id of connectedSites) {
            output[id.toString()].connected = true
        }
        // Same customer
        const customer_id = siteList[siteID]?.customer_id
        if (customer_id) {
            for (const id of Object.keys(siteList)) {
                if (siteList[id]["customer_id"] === customer_id) {
                    if (output[id.toString()] === undefined) {
                        output[id.toString()] = {...siteList[id]}
                    }
                    output[id.toString()].same_customer = true
                }
            }
        }
        return output
    }, [connectedSites, favoritesDict, recent, siteID, siteList])


    const site = useMemo(() => (augmentedSiteList[siteID]), [siteID, augmentedSiteList])

    return (
        <>
            <AdminSiteSelectorButton
                site={site}
                toggleMenu={toggleMenu}
                toggleFavorite={toggleFavorite}
            />
            <AdminSiteSelectorMenu
                siteID={siteID}
                open={open}
                anchorEl={anchorEl}
                closeMenu={closeMenu}
                siteList={augmentedSiteList}
                changeSite={closeAndChangeSite}
                favorites={favoritesDict}
                toggleFavorite={toggleFavorite}
                isSmallScreen={isSmallScreen}
            />
        </>
    );
}
