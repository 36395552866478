import {useCallback, useEffect, useMemo, useState} from "react";
import {useSite, useUser} from "../../app/globalHooks";
import {useDbItem} from "../../utils/rdbHooks";
import {API_GET_MESSAGE_SINCE, API_POST_MESSAGE, RDB_CHAT} from "../../utils/constant";
import {backendFetch, methodType} from "../../utils/backendHelper";

export const useChatBackend = () => {
    const [lastMessageTime, setLastMessageTime] = useState(null)
    const {site_id, loaded} = useSite();

    const {item, loading} = useDbItem(RDB_CHAT, site_id)
    const {user, loading: loadingUser} = useUser()
    const [allMessages, setAllMessages] = useState([])

    const [sending, setSending] = useState(false);

    const [currentSiteid, setCurrentSiteid] = useState(null)

    const rdbLastMessage = useMemo(() => {
        if (loading) return null
        if (!item) return null
        return item?.last_message
    }, [item, loading])

    useEffect(() => {

        if (!user || loadingUser || !loaded) return;

        if (!site_id || site_id !== currentSiteid || lastMessageTime < rdbLastMessage) {

            setCurrentSiteid(site_id);
            let queryParams = {}
            if (site_id) {
                queryParams = {site_id}
            }
            setSending(true);
            backendFetch({
                path: API_GET_MESSAGE_SINCE, method: methodType.GET, user, queryParams, data: {since: lastMessageTime},
            }).then(json_data => {
                let biggestTime = lastMessageTime
                // look for the biggest time
                for (let message of json_data) {
                    if (message.date > biggestTime) {
                        biggestTime = message.date
                    }
                }
                // make sure the json_data is sorted by date
                json_data.sort((a, b) => a.date - b.date)
                // if the rdbLastMessage is null it means we received fake messages, we can't store the biggest time
                if (!rdbLastMessage) {
                    setLastMessageTime(rdbLastMessage)
                } else {
                    setLastMessageTime(biggestTime)
                }
                setAllMessages(json_data)
            }).finally(() => {
                setSending(false);
            })
        }

    }, [lastMessageTime, loaded, loadingUser, rdbLastMessage, site_id, user, currentSiteid])

    const postMessage = useCallback((text, language = "fr") => {
        backendFetch({
            path: API_POST_MESSAGE, method: methodType.POST, user, queryParams: {site_id}, data: {
                role: "user", text, language,
            },
        }).then(json_data => {
            console.log("json_data", json_data)
        })
    }, [site_id, user])

    const lastMessage = useMemo(() => {
        // Look for the last message in message whose role is user or assistant
        if (allMessages.length === 0) return null

        for (let i = allMessages.length - 1; i >= 0; i--) {
            if (allMessages[i].role === "user" || allMessages[i].role === "assistant") {
                return allMessages[i]
            }
        }

        return null
    }, [allMessages])

    const lastContext = useMemo(() => {
        // Look for the last message in message whose role is context
        if (allMessages.length === 0) return {}

        for (let i = allMessages.length - 1; i >= 0; i--) {
            if (allMessages[i].role === "context") {
                return allMessages[i]
            }
        }

        return {}
    }, [allMessages])

    const placeholder = useMemo(() => {
        // Either the lastMessage has a placeholder, return it
        if (lastMessage?.placeholder) return lastMessage?.placeholder
        // Or the lastContext has a placeholder, return it
        if (lastContext?.placeholder) return lastContext.placeholder
        // Or return null
        return null
    }, [lastContext.placeholder, lastMessage?.placeholder])

    const suggestions = useMemo(() => {
        // Either the lastMessage has suggestions, return them
        if (lastMessage?.suggestions) return lastMessage?.suggestions
        // Or the lastContext has suggestions, return them
        if (lastContext?.suggestions) return lastContext.suggestions
        // Or return null
        return null
    }, [lastContext.suggestions, lastMessage?.suggestions])

    const messages = useMemo(() => {
        // filter out only messages with role user  or assistant
        return allMessages.filter((message) => message.role === "user" || message.role === "assistant")
    }, [allMessages])

    return {lastMessageTime, messages, allMessages, postMessage, sending, lastMessage, lastContext, placeholder, suggestions}


}
