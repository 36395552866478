import React, {useCallback, useMemo} from 'react';
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import HomePage from "./homePage/HomePage";
import NewCampaign from "./newCampaign/NewCampaign";
import Overview from "./overview/Overview";
import MyCampaigns from "./myCampaigns/MyCampaigns";
import Subscription from "./subscription/Subscription";
import Integration from "./integration/Integration";
import AddAccounts from "./addAccounts/AddAccounts";
import CreditsSettings from "./creditsSettings/CreditsSettings";
import Credits from "./credits/Credits";
import {useSiteListLoaded, useSiteLoaded, useUser} from "../../app/globalHooks";
import {LoadingPage} from "../open/LoadingPage";
import {BackendSettings} from "../admin/backendSettings/BackendSettings";

import sample_routes from "../../sample/SampleRoutes";
import {
    PATH_ACCOUNT_SETTINGS,
    PATH_ADD_ACCOUNT,
    PATH_AI_CAMPAIGN,
    PATH_ASSET_EDITION,
    PATH_BACKEND_SETTINGS,
    PATH_CAMPAIGN_NEW,
    PATH_CAMPAIGNS,
    PATH_CHAT,
    PATH_CREDITS,
    PATH_CREDITS_SETTINGS,
    PATH_HOME, PATH_INTEGRATION,
    PATH_LOGIN,
    PATH_OPENAI_GENERATION,
    PATH_OVERVIEW,
    PATH_SITE_SETTINGS,
    PATH_SUBSCRIPTION
} from "../../utils/constant";
import {AdminModeRequired} from "../../features/adminMode/adminModeComponents";
import AccountSettings from "./accountSettings";
import SiteSettings from "./siteSettings/SiteSettings";
import {useAdminMode} from "../../features/adminMode/adminModeContext";
import {OpenAIGeneration} from "../admin/openaigeneration/OpenAIGeneration";
import {AssetsEdition} from "./assetsEdition/AssetsEdition";
import ChatContainer from "../../widgets/chat/ChatContainer";
import {AiCampaign} from "./AiCampaign/AiCampaign";

const Authenticated = ({children}) => {
    const {user, loading} = useUser();
    const site_ready = useSiteLoaded();
    const site_list_ready = useSiteListLoaded();
    const location = useLocation();

    const redirect = encodeURI(`${location.pathname}${location.search}`);

    const child_element = useCallback((children, is_authenticated) => {
        if (is_authenticated) {
            return children;
        } else if (loading || !site_ready || !site_list_ready) {
            return <LoadingPage redirect={location.pathname} search={location.search} loading={loading}/>
        } else {
            return <Navigate to={`${PATH_LOGIN}?redirect=${redirect}`}/>
        }
    }, [loading, location, redirect])

    return (
        <>{child_element(children, user !== null)}</>
    );
}


const AuthenticatedRoutes = () => {
    const {isAdmin, adminMode} = useAdminMode();

    const isLocal = useMemo(() => (window.location.hostname === "localhost"), [])

    const secured_routes = useMemo(() => {

        const secure_route = (route) => {
            const {path, element, needAdmin} = route;
            if (needAdmin) {
                return <Route
                    key={path}
                    path={path}
                    element={<Authenticated><AdminModeRequired>{element}</AdminModeRequired></Authenticated>}
                />
            }
            return (
                <Route key={path} path={path} element={<Authenticated>{element}</Authenticated>}/>
            )
        }

        const routes = [
            {path: PATH_HOME, element: <ChatContainer/>},
            {path: PATH_CAMPAIGN_NEW, element: <NewCampaign/>},
            {path: PATH_OVERVIEW, element: <Overview/>},
            {path: PATH_CAMPAIGNS, element: <MyCampaigns/>},
            {path: PATH_CAMPAIGN_NEW, element: <Subscription/>},
            {path: PATH_INTEGRATION, element: <Integration/>},
            {path: PATH_ADD_ACCOUNT, element: <AddAccounts/>},
            {path: PATH_ACCOUNT_SETTINGS, element: <AccountSettings/>},
            {path: PATH_CREDITS_SETTINGS, element: <CreditsSettings/>},
            {path: PATH_SITE_SETTINGS, element: <SiteSettings/>},
            {path: PATH_CREDITS, element: <Credits/>},
            {path: PATH_ASSET_EDITION, element: <AssetsEdition/>},
            {path: PATH_CHAT, element: <ChatContainer/>},
            {path: PATH_AI_CAMPAIGN, element: <AiCampaign/>},
            {path: '*', element: <HomePage/>},
        ]

        if ((isLocal) || (isAdmin && adminMode)) {
            routes.push({path: PATH_BACKEND_SETTINGS, element: <BackendSettings/>});
            routes.push({path: PATH_OPENAI_GENERATION, element: <OpenAIGeneration/>});
            routes.push(...sample_routes);
        }

        return routes.map(secure_route);

    }, [isLocal, isAdmin, adminMode])

    return (
        <Routes>
            {secured_routes}
        </Routes>
    );
}

export default AuthenticatedRoutes;
