import {AzaTypography} from "../../../components/mui/AzaTypography";
import {AzaBox} from "../../../components/mui/AzaBox";
import {AzaSelect} from "../../../components/mui/AzaSelect";
import React, {useCallback, useState} from "react";
import {AzaMenuItem} from "../../../components/mui/AzaMenu";
import {AzaGridContainer, AzaGridItem} from "../../../components/mui/AzaGrid";
import {AzaButton} from "../../../components/mui/AzaButton";
import {useUser} from "../../../app/globalHooks";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material";
import {useDispatch} from "react-redux";
import {
    actionExposedUpdateAssetScope,
    actionSetCountries,
    actionSetDailyBudget,
    actionSetLanguages,
    actionUpdateNetworks
} from "../../../containers/createNewCampaign/reducers/createNewCampaignSlice";
import {useNavigate} from "react-router-dom";
import {PATH_CAMPAIGN_NEW} from "../../../utils/constant";
import {AzaDivider} from "../../../components/mui/AzaDivider";
import {AzaStack} from "../../../components/mui/AzaStack";
import {AzaCaretDownOutlined} from "../../../components/mui/AzaIcons";


const campaignTypes = [{
    label: "Performance Max", value: "PerfMax",
}, {
    label: "Search", value: "Search", disabled: true,
}, {
    label: "Display", value: "Display", disabled: true,
}, {
    label: "Shopping", value: "Shopping", disabled: true,
},]

const campaignSubTypes = [{
    label: "aicampaign.subtypes.products", value: "Product", disabled: true,
}, {
    label: "aicampaign.subtypes.brand", value: "Brand",
}]

const campaignLanguages = [{
    label: "aicampaign.languages.french", value: "FR",
}, {
    label: "aicampaign.languages.english", value: "EN",
},]

const campaignBudgets = [{
    label: "5€", value: 5,
}, {
    label: "10€", value: 10,
}, {
    label: "20€", value: 20,
}, {
    label: "50€", value: 50,
}, {
    label: "100€", value: 100,
}]

const campaignNetworks = [{
    label: "Google Ads", value: "GoogleAds",
}, {
    label: "Azameo RTB", value: "AzameoRTB", disabled: true,
}, {
    label: "Microsoft Ads", value: "MicrosoftAds", disabled: true,
}, {
    label: "All networks", value: "AllNetworks", disabled: true,
}]

const campaignSegments = [{
    label: "aicampaign.segments.newscustomers", value: "NewCustomers", disabled: true,
}, {
    label: "aicampaign.segments.customers", value: "ExistingCustomers", disabled: true,
}, {
    label: "aicampaign.segments.all", value: "BothNewAndExistingCustomers",
}]

const campaignCountries = [{
    label: "aicampaign.countries.fr", value: "fr",
}, {
    label: "aicampaign.countries.uk", value: "uk",
}, {
    label: "aicampaign.countries.be", value: "be",
}, {
    label: "aicampaign.countries.all", value: "all",
}]

const SelectValue = ({value, isEditing}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (<AzaTypography color={theme.palette.primary.main} sx={{fontWeight: "bold"}}>{t(value.label)} {!isEditing &&
        <AzaCaretDownOutlined/>}</AzaTypography>)
}

const InlineEditSelect = ({options, defaultValue, onChange}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(defaultValue);
    const {t} = useTranslation();

    const handleValueChange = (event) => {
        setValue(event.target.value);
        onChange(event.target.value);
        setIsEditing(false);
    };

    return (<>
        {isEditing ? (<AzaSelect
            value={value}
            onChange={handleValueChange}
            onBlur={() => setIsEditing(false)}
            onClose={() => setIsEditing(false)}
            autoFocus
            open={isEditing}  // This prop controls whether the Select is open or not
            sx={{minWidth: 'auto'}}
            renderValue={(selected) => <SelectValue value={selected} isEditing={isEditing}/>}
            variant="standard"
        >
            {options.map((option, index) => (<AzaMenuItem key={index} value={option} disabled={option.disabled}>
                {t(option.label)}
            </AzaMenuItem>))}
        </AzaSelect>) : (<AzaBox
            display="inline-block"
            onClick={() => setIsEditing(true)}
            sx={{cursor: 'pointer'}}
        >
            <SelectValue value={value} isEditing={isEditing}/>
        </AzaBox>)}
    </>);
}

const Phrase = ({items}) => {
    return (<AzaGridContainer
        direction={"row"}
        justifyContent="flex-start"
        alignItems="center"
        sx={{
            minHeight: "50px",
        }}
        spacing={1}
    >
        {items.map((item, index) => (<AzaGridItem key={index}>
            {typeof item === "string" ? (<AzaTypography variant={"body1"}>
                {item}
            </AzaTypography>) : (item)}
        </AzaGridItem>))}
    </AzaGridContainer>)
}


export const AiCampaign = ({disabled, onDone}) => {

    const {user, loading: loadingUser} = useUser()
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    const [campaignType, setCampaignType] = useState(campaignTypes[0])
    const [campaignSubType, setCampaignSubType] = useState(campaignSubTypes[1])
    const [campaignLanguage, setCampaignLanguage] = useState(campaignLanguages[0])
    const [campaignBudget, setCampaignBudget] = useState(campaignBudgets[0])
    const [campaignNetwork, setCampaignNetwork] = useState(campaignNetworks[0])
    const [campaignSegment, setCampaignSegment] = useState(campaignSegments[2])
    const [campaignCountry, setCampaignCountry] = useState(campaignCountries[0])

    const createCampaign = useCallback(() => {
        if (loadingUser) return;
        if (!user) return;

        dispatch(actionSetDailyBudget({daily_budget: campaignBudget.value}));
        dispatch(actionSetLanguages(campaignLanguage.value));
        dispatch(actionSetCountries(campaignCountry.value));
        dispatch(actionExposedUpdateAssetScope(campaignSubType.value));
        dispatch(actionUpdateNetworks({name: campaignType.value, network_types: {[campaignNetwork.value]: true}}));

        if(onDone){
            onDone();
        }

        navigate({pathname: PATH_CAMPAIGN_NEW});


    }, [loadingUser, campaignType.value, campaignSubType.value, campaignLanguage.value, campaignBudget.value, campaignNetwork.value, campaignCountry.value, user, dispatch, navigate, onDone])


    return (<>
        <AzaStack alignItems={"flex-start"}>
            <AzaGridItem>
                <AzaTypography variant={"body"}>{t("aicampaign.description")} </AzaTypography>
            </AzaGridItem>
            <AzaGridItem>
                <AzaTypography variant={"body"}>{t("aicampaign.info")}</AzaTypography>
            </AzaGridItem>
        </AzaStack>
        <AzaDivider sx={{borderColor: theme.palette.secondary.main}}/>
        <AzaStack alignItems={"flex-end"}>
            <AzaGridItem>
                <Phrase
                    items={[t("aicampaign.create"), <InlineEditSelect
                        options={campaignTypes}
                        defaultValue={campaignType}
                        onChange={setCampaignType}
                    />, t("aicampaign.promote"), <InlineEditSelect
                        options={campaignSubTypes}
                        defaultValue={campaignSubType}
                        onChange={setCampaignSubType}
                    />, t("aicampaign.on"), <InlineEditSelect
                        options={campaignNetworks}
                        defaultValue={campaignNetwork}
                        onChange={setCampaignNetwork}
                    />,]}
                />
            </AzaGridItem>
            <AzaGridItem>
                <Phrase
                    items={[t("aicampaign.target"), <InlineEditSelect
                        options={campaignSegments}
                        defaultValue={campaignSegment}
                        onChange={setCampaignSegment}
                    />, t("aicampaign.in"), <InlineEditSelect
                        options={campaignCountries}
                        defaultValue={campaignCountry}
                        onChange={setCampaignCountry}
                    />, t("aicampaign.to"), <InlineEditSelect
                        options={campaignLanguages}
                        defaultValue={campaignLanguage}
                        onChange={setCampaignLanguage}
                    />]}
                />
            </AzaGridItem>
            <AzaGridItem>
                <Phrase
                    items={[t("aicampaign.spend"), <InlineEditSelect
                        options={campaignBudgets}
                        defaultValue={campaignBudget}
                        onChange={setCampaignBudget}
                    />, t("aicampaign.perday")]}
                />
            </AzaGridItem>
        </AzaStack>
        <br/>
        <AzaGridContainer direction={"row"}>
            <AzaGridItem xs>
            </AzaGridItem>
            <AzaGridItem>
                <AzaButton
                    onClick={createCampaign}
                    variant={"contained"}
                    disabled={disabled}
                >{t("aicampaign.button")}
                </AzaButton>
            </AzaGridItem>
        </AzaGridContainer>
    </>)
}
