import {useTranslation} from "react-i18next";

import {AzaBox} from "../../components/mui/AzaBox";
import HorizontalCreationStepper from "./createNewCampaignUtile/StepByStep";

const CreateNewCampaign = () => {
    const {t} = useTranslation();

    return (
        <AzaBox sx={{position: 'relative', maxWidth: '1400px', m: '0 auto'}}>
            <AzaBox sx={{mb: '15px'}}>
                {t('campaign.creation.campaign')}
            </AzaBox>
            <AzaBox sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                <HorizontalCreationStepper/>
            </AzaBox>
        </AzaBox>
    );
};

export default CreateNewCampaign;