import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import BaseCardTemplate from "../../createNewCampaignUtile/BaseCardTemplate";
import {AzaBox} from "../../../../components/mui/AzaBox";
import {AzaInputAdornment} from "../../../../components/mui/AzaInputAdornment";
import AzaInputNumber from "../../../../components/azameo/AzaInputNumber";
import {usePayment} from "../../../payment/paymentGroup/paymentHook";
import {MIN_BUDGET_AMOUNT, MIN_TARGET_ROI} from "../../tempHardcodedConst";
import {AzaFormControlLabel} from "../../../../components/mui/AzaFormControlLabel";
import {AzaCheckbox} from "../../../../components/mui/AzaCheckbox";
import {AzaFormGroup} from "../../../../components/mui/AzaFormGroup";
import {selectSiteSettingsReady} from "../../../../app/globalSlice";
import {
    actionSetDailyBudget,
    actionSetCheckedExceedBudget,
    actionSetExceedDailyBudget,
    selectDailyBudget,
    selectCheckedExceedBudget,
    selectExceedDailyBudget
} from "../../reducers/createNewCampaignSlice";
import {AzaAlert} from "../../../../components/mui/AzaAlert";

const BudgetSettings = () => {
    const {t} = useTranslation();
    const settingsReady = useSelector(selectSiteSettingsReady);
    const dailyBudgetData = useSelector(selectDailyBudget);
    const isExceedChecked = useSelector(selectCheckedExceedBudget);
    const exceedDailyBudgetData = useSelector(selectExceedDailyBudget);
    const {currency} = usePayment();
    const currencySymbol = (settingsReady)?t(`currency.${currency}`):"";
    const dispatch = useDispatch();

    const handleBudgetInput = (e) => {
        // if amount to big...
        dispatch(actionSetDailyBudget({daily_budget: Number(e.target.value)}));
    }

    const handleCheckboxChange = (e) => {
        dispatch(actionSetCheckedExceedBudget(e.target.checked));
    }

    const handleExceedInput = (e) => {
        // if amount to big...
        dispatch(actionSetExceedDailyBudget({exceed_daily_budget: e.target.value}));
    }

    // Part of validation: on Blur check if amount not too small
    // const handleFocusOut = (e) => {
    //     const val = e.target.value;
    //     const minVal = e.target.getAttribute('data-minval');
    //
    //     if(Number(val) < Number(minVal)) {
    //         // debugger;
    //         setError(true);
    //     }
    // }

    return (
        <AzaBox sx={{mb: '30px'}}>
            <BaseCardTemplate title={t('settings.budget-settings')}>
                <AzaBox sx={{mb: '30px'}}>
                    <AzaBox sx={{mb: '15px', display: 'flex'}}>
                        <AzaBox sx={{mr: '5px'}}>
                            {t('campaigns_data.min-average-daily-budget', {amount: MIN_BUDGET_AMOUNT, currencySymbol: currencySymbol})}
                        </AzaBox>
                    </AzaBox>
                    <AzaBox>
                        <AzaInputNumber
                            sx={{maxWidth: '100px'}}
                            required
                            error={dailyBudgetData?.error?.status}
                            placeholder={t('payment.amount.custom.placeholder')}
                            value={dailyBudgetData.daily_budget > 0 && dailyBudgetData.daily_budget}
                            onChange={handleBudgetInput}
                            // onBlur={handleFocusOut}
                            size="small"
                            inputProps={{"data-minval": MIN_BUDGET_AMOUNT}}
                            helperText={''}
                            InputProps={{
                                endAdornment: <AzaInputAdornment position="end">{currencySymbol}</AzaInputAdornment>,
                            }}
                        />
                    </AzaBox>
                    {dailyBudgetData?.error?.status &&
                        <AzaAlert severity="error" sx={{marginTop: '15px'}}>
                            <AzaBox>
                                {
                                    dailyBudgetData?.error?.message && <AzaBox>{dailyBudgetData.error.message}</AzaBox>
                                }
                            </AzaBox>
                        </AzaAlert>}
                </AzaBox>

                <AzaBox>
                    <AzaBox sx={{mb: '15px', display: 'flex', alignItems: 'center'}}>
                        <AzaFormGroup>
                            <AzaFormControlLabel
                                control={
                                    <AzaCheckbox
                                        checked={isExceedChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label={t('campaigns_data.exceed-roi-checkbox')}
                            />
                        </AzaFormGroup>
                        {isExceedChecked && <AzaInputNumber
                            sx={{maxWidth: '100px'}}
                            required
                            error={exceedDailyBudgetData?.error?.status}
                            // placeholder={t('payment.amount.custom.placeholder')}
                            value={exceedDailyBudgetData.exceed_daily_budget}
                            onChange={handleExceedInput}
                            // onBlur={handleFocusOut}
                            size="small"
                            inputProps={{"data-minval": MIN_TARGET_ROI}}
                            helperText={''}
                            InputProps={{
                                endAdornment: <AzaInputAdornment position="end">{'%'}</AzaInputAdornment>,
                            }}
                        />}
                    </AzaBox>
                    {exceedDailyBudgetData?.error?.status &&
                        <AzaAlert severity="error" sx={{marginTop: '15px'}}>
                            <AzaBox>
                                {
                                    exceedDailyBudgetData?.error?.message && <AzaBox>{exceedDailyBudgetData.error.message}</AzaBox>
                                }
                            </AzaBox>
                        </AzaAlert>}
                </AzaBox>
            </BaseCardTemplate>
        </AzaBox>
    );
};

export default BudgetSettings;