import {ThemeProvider, Typography, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";

import {signOutUser} from "../../utils/firebase/firebaseHelper";
import {useTranslationMode} from "../../features/adminMode/translationModeContext";
import {useTranslation} from "react-i18next";
import {useAdminModeProvider} from "../../features/adminMode/adminModeContext";
import React, {useMemo, useState} from "react";
import {LanguageSelector} from "./LanguageSelector";

import {PATH_ACCOUNT_SETTINGS} from "../../utils/constant";
import {AzaIconButton} from "../../components/mui/AzaButton";
import {useUser} from "../../app/globalHooks";
import AzaBadge from "../../components/mui/AzaBadge";
import {AzaMenu, AzaMenuItem} from "../../components/mui/AzaMenu";
import {
    AzaAdminPanelSettingsIcon,
    AzaContrastIcon,
    AzaPowerSettingsNew,
    AzaSettings,
    AzaTranslate
} from "../../components/mui/AzaIcons";
import {useThemeMode} from "../../app/useThemeMode";
import {Language} from "@mui/icons-material";
import {AdminModeBranch} from "../../features/adminMode/adminModeComponents";
import {ThemeCustomization} from "../../themes";
import {UserBadge} from "./UserBadge";


const UserSettingsItem = ({name, icon, onClick, }) => {
    return (
        <AzaMenuItem key={name} onClick={onClick}>
            <AzaIconButton>
                {icon}
            </AzaIconButton>
            {name}
        </AzaMenuItem>
    )
}


const UserSettingsToggleItem = ({name, icon, onClick, state, theme}) => {

    const currenttheme = useTheme();
    const innertheme = theme ?? currenttheme;

    return (
        <AzaMenuItem key={name} onClick={onClick}>
            <ThemeProvider theme={innertheme}>
                <AzaIconButton color={state?"primary":"default"}>
                    {icon}
                </AzaIconButton>
                <Typography sx={{fontWeight: state?"bold":"light"}}>{name}</Typography>
            </ThemeProvider>
        </AzaMenuItem>
    )
}


export const UserSettingsButton = () => {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
    const {user, userInfos, loading} = useUser()

    const userActionRequested = useMemo(() => {
        let actionRequested = 0;

        if (!user || loading) {
            return null;
        }

        if (!user.emailVerified){
            actionRequested += 1;
        }

        if (actionRequested > 0){
            return actionRequested;
        }
        return null;
    }, [user, loading])

    const userInfo = useMemo(() => {
        if (userInfos){
            return userInfos;
        }
        return {};
    }, [userInfos])

    const toggleSettingsMenu = (event) => {
        setAnchorElUser(event.currentTarget);
        setSettingsMenuOpen(!settingsMenuOpen);
    }

    return (
        <>
            <AzaIconButton onClick={toggleSettingsMenu}>
                <UserBadge badgeContent={userActionRequested}/>
            </AzaIconButton>
            <UserSettingsMenu isOpen={settingsMenuOpen}
                              closeSettingsMenu={() => setSettingsMenuOpen(false)}
                              anchorElUsr={anchorElUser}/>

        </>
        )

}

export const UserSettingsMenu = ({isOpen, closeSettingsMenu, anchorElUsr, actionRequested}) => {

    const {t} = useTranslation();
    const {canTranslate,  translating, toggleTranslationMode} = useTranslationMode()
    const {isAdmin, adminMode, toggleAdminMode} = useAdminModeProvider()
    const navigate = useNavigate();
    const {themeMode, toggleThemeMode} = useThemeMode();

    const handleOpenUserSettings = () => {
        navigate(PATH_ACCOUNT_SETTINGS);
    }

    return (
        <>
            <AzaMenu
                anchorEl={anchorElUsr}
                open={isOpen}
                onClose={closeSettingsMenu}
            >
                <UserSettingsItem
                    name={t('settings.admin.accountsettings')}
                    icon={<AzaBadge badgeContent={actionRequested}
                                    variant={actionRequested?"dot":undefined}
                    ><AzaSettings/></AzaBadge>}
                    onClick={handleOpenUserSettings}
                />
                <AzaMenuItem>
                    <AzaIconButton>
                        <Language/>
                    </AzaIconButton>
                    <LanguageSelector sx={{pt:"8px"}} large withIcon={false}/>
                </AzaMenuItem>
                <AdminModeBranch needAdmin={isAdmin}>
                    <UserSettingsToggleItem
                        icon={<AzaContrastIcon/>}
                        name={t("settings.admin.dark_mode")}
                        onClick={toggleThemeMode}
                        state={themeMode === 'dark'}
                    />
                </AdminModeBranch>
                <UserSettingsItem
                    name={t('settings.admin.signout')}
                    icon={<AzaPowerSettingsNew/>}
                    onClick={signOutUser}
                />
                {(canTranslate || isAdmin) && <AzaMenuItem divider/>}
                {(isAdmin) &&
                    <ThemeCustomization presetColor={"themeColorsAdmin"}>
                        <UserSettingsToggleItem
                        icon={<AzaAdminPanelSettingsIcon/>}
                        state={adminMode}
                        onClick={toggleAdminMode}
                        name={t("settings.admin.admin_mode")}/>
                    </ThemeCustomization>
                }
                {(canTranslate) && <UserSettingsToggleItem
                    icon={<AzaTranslate/>}
                    state={translating}
                    onClick={toggleTranslationMode}
                    name={t("settings.admin.translation_mode")}/>}
            </AzaMenu>
        </>
    )
}

