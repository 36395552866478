import {AzaCardBase, AzaCardContent, AzaCardHeader} from "../../../components/mui/AzaCard";
import {AzaDivider} from "../../../components/mui/AzaDivider";

const BaseCardTemplate = (props) => {
    const {children, title, component = null} = props;

    return (
        <AzaCardBase
            variant="outlined"
            sx={{boxShadow: 'none',  overflow: 'unset'}}
        >
            <AzaCardHeader title={title} component={component}/>
            <AzaDivider/>
            <AzaCardContent>
                {children}
            </AzaCardContent>
        </AzaCardBase>
    );
};

export default BaseCardTemplate;