import {Error, Close} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

import {AzaBox} from "../../../components/mui/AzaBox";
import {AzaTypography} from "../../../components/mui/AzaTypography";
// import LinearWithValueLabel from "../progressWidget/LinearProgress";
import {AzaGridContainer, AzaGridItem} from "../../../components/mui/AzaGrid";
import {secondaryText} from "../../../utils/colors";
import {AzaPaper} from "../../../components/mui/AzaPaper";

const CompletionBlock = ({title, recommendation, error}) => {
    const {t} = useTranslation();

    return (
        <AzaPaper
            variant="outlined"
            sx={{
                mt: '16px',
                padding: '20px 16px',
            }}
        >
            <AzaTypography variant="h5" gutterBottom sx={{mb: '20px'}}>
                {title}
            </AzaTypography>
            {/*<AzaBox sx={{mb: '20px'}}>*/}
            {/*    <LinearWithValueLabel progress={progress}/>*/}
            {/*</AzaBox>*/}
            {recommendation && <AzaBox sx={{mb: '10px'}}>
                {/* Probably information will come from reducer */}
                <AzaGridContainer sx={{alignItems: 'center'}} spacing={5}>
                    <AzaGridItem xs={2}>
                        <Error sx={{fontSize: '35px', color: '#ffd666'}}/>
                    </AzaGridItem>
                    <AzaGridItem xs={10}>
                        <AzaTypography>{t('recommendation')}</AzaTypography>
                        <AzaTypography sx={{color: secondaryText}}>{recommendation}</AzaTypography>
                    </AzaGridItem>
                </AzaGridContainer>
            </AzaBox>}
            {error > 0 && <AzaBox>
                {/* Probably information will come from reducer */}
                <AzaGridContainer sx={{alignItems: 'center'}} spacing={5}>
                    <AzaGridItem xs={2}>
                        <Close
                            sx={{fontSize: '33px', color: '#f5222d', backgroundColor: '#fff1f0', borderRadius: '50%'}}/>
                    </AzaGridItem>
                    <AzaGridItem xs={10} sx={{display: 'flex'}}>
                        <AzaTypography>
                            {t('errors-number')}
                            <AzaBox component={"span"} sx={{fontWeight: 'bold'}}>&nbsp;{error}</AzaBox>
                        </AzaTypography>
                    </AzaGridItem>
                </AzaGridContainer>
            </AzaBox>}
        </AzaPaper>
    );
};

export default CompletionBlock;