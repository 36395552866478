import React, {useEffect, useRef} from 'react';
import {AzaGridContainer, AzaGridItem} from "../../components/mui/AzaGrid";
import {AzaTypography} from "../../components/mui/AzaTypography";
import {AzaCardBase, AzaCardContent} from "../../components/mui/AzaCard";
import {LinearProgress, useTheme} from "@mui/material";
import {AzameoBadge, UserBadge} from "../../containers/topbar/UserBadge";
import {AzaBox} from "../../components/mui/AzaBox";
import {AiCampaign} from "../../pages/authenticated/AiCampaign/AiCampaign";
import {useTranslation} from "react-i18next";
import {AddSite} from "../AddSite/AddSite";
import {useChatProvider} from "./chatContext";

const BaseMessage = ({message, children}) => {
    const theme = useTheme();
    const isUser = (message.role === "user")
    return (
        <AzaCardBase sx={{
            background:isUser ? theme?.palette.background.paper : theme?.palette.secondary.lighter,
            width: "100%",
            paddingRight: 0
        }}>
            <AzaCardContent sx={{width:"100%"}}>
                <AzaGridContainer>
                    <AzaGridItem sm={1}>
                        {isUser && <UserBadge />}
                        {!isUser && <AzameoBadge />}
                    </AzaGridItem>
                    <AzaGridItem sm={11}>
                        {children}
                    </AzaGridItem>
                    <AzaGridItem sm={1}>
                    </AzaGridItem>
                </AzaGridContainer>
            </AzaCardContent>
        </AzaCardBase>
    );
};


const MessageCreateCampaign =({message, loading, last, onDone}) => {
    return (
        <BaseMessage message={message}>
            <AiCampaign disabled={!last} loading={loading} onDone={onDone}/>
        </BaseMessage>)
}

const MessageAddSite =({message, loading, last, onDone}) => {
    return (
        <BaseMessage message={message}>
            <AddSite disabled={!last} loading={loading} onDone={{onDone}}/>
        </BaseMessage>)
}


const Message = ({message, loading, last}) => {

    const {t} = useTranslation();
    const {onSubmitPrompt} = useChatProvider();

    const onDone = () => {
        onSubmitPrompt(t("chat.suggestions.done"));
    }

    if (message.action) {
        switch (message.action) {
            case "create_campaign":
                return <MessageCreateCampaign message={message} loading={loading} last={last} onDone={onDone} {...message.context} />
            case "add_site":
                return <MessageAddSite message={message} loading={loading} last={last} onDone={onDone} {...message.context}/>
            default:
                break;
        }
    }

    return (
        <BaseMessage message={message} loading={loading} last={last}>
            {message.text.split('\n').map((line, idx) => {
                return (
                    <AzaTypography key={idx} variant="body1">
                        {t(line)}
                    </AzaTypography>)
            })}
        </BaseMessage>
    );
};

export const MessageList = ({messages, bottompadding, loading}) => {
    const bottomRef = useRef(null);

    useEffect(() => {
        bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    return (
        <>
            {messages.map((message, index) => (
                <AzaGridItem  key={index}>
                    <Message message={message} last={index===messages.length-1}/>
                </AzaGridItem>
            ))}
            <AzaGridItem >
                {loading && <><LinearProgress/></>}
            </AzaGridItem>
            <AzaGridItem >
                <AzaBox style={{height: bottompadding}} ref={bottomRef}></AzaBox>
            </AzaGridItem>
        </>
    )
}


