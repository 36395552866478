import {CircularProgress, styled, Typography} from "@mui/material";
import {
    Add,
    AddCircle,
    Circle,
    Close,
    CloseRounded,
    ContentCopy,
    DataArray,
    DataObject,
    Delete,
    Edit,
    FormatLineSpacing,
    Forum,
    HelpOutline,
    KeyboardArrowDown,
    KeyboardArrowRight,
    Notifications,
    PauseCircleOutline,
    PlayCircleOutline,
    PowerSettingsNew,
    Settings,
    Sync,
    Translate,
    ViewColumn,
    AutoAwesome,
    BackupTable,
    Dashboard,
    Tune,
    LocalCafe,
    InsertLink, RadioButtonChecked,
    MoveToInbox, Send, Chat,
} from "@mui/icons-material";

import ErrorIcon from "@mui/icons-material/Error";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MenuIcon from "@mui/icons-material/Menu";
import ContrastIcon from "@mui/icons-material/Contrast";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';

import {
    CaretDownOutlined, CaretUpOutlined,
    CheckSquareFilled, CrownOutlined, FallOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    MinusSquareFilled, RiseOutlined
} from "@ant-design/icons";

export const AzaIconNumber = styled((props) => {
    return (
        <div style={{
            position: "relative",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center"
        }}
        >
            <Circle color={props.color} />
            <Typography component="span" sx={{
                position: "absolute",
                lineHeight: 1,
                color: "#fff",
                top: "0.35em",
                left: "0.45em",
                fontSize: "1em"
            }}>
                {props.count}
            </Typography>
        </div>
    );
})(() => ({}));

export const AzaAccountBalanceIcon = (props) => {return (<AccountBalanceIcon {...props}/>)};
export const AzaAdd = (props) => {return (<Add {...props}/>)};
export const AzaAddCircle = (props) => {return (<AddCircle {...props}/>)}
export const AzaAdminPanelSettingsIcon = (props) => {return (<AdminPanelSettingsIcon {...props}/>)}
export const AzaBackupTable = (props) => {return (<BackupTable {...props}/>)}
export const AzaCafe = (props) => {return (<LocalCafe {...props}/>)}
export const AzaCaretDownOutlined = (props) => {return <CaretDownOutlined {...props} />}
export const AzaCaretUpOutlined = (props) => {return <CaretUpOutlined {...props} />}
export const AzaChat = (props) => {return <Chat {...props} />}
export const AzaContentCopy = (props) => {return <ContentCopy {...props} />}
export const AzaCheckSquareFilled = (props) => {return (<CheckSquareFilled {...props}/>)}
export const AzaCircularProgress = (props) => {return (<CircularProgress {...props}/>)}
export const AzaContrastIcon = (props) => {return (<ContrastIcon {...props}/>)}
export const AzaCloseRounded = (props) => {return (<CloseRounded {...props}/>)}
export const AzaCreditCardIcon = (props) => {return (<CreditCardIcon {...props}/>)}
export const AzaCrownOutlined = (props) => {return (<CrownOutlined  {...props}/>)}
export const AzaDashboard = (props) => {return (<Dashboard  {...props}/>)}
export const AzaDataArray = (props) => {return (<DataArray {...props}/>)}
export const AzaDataObject = (props) => {return (<DataObject {...props}/>)}
export const AzaDelete = (props) => {return (<Delete {...props}/>)}
export const AzaEdit = (props) => {return (<Edit {...props}/>)}
export const AzaErrorIcon = (props) => {return (<ErrorIcon {...props}/>)}
export const AzaExtensionIcon = (props) => {return (<ExtensionOutlinedIcon {...props}/>)}
export const AzaFallOutlined = (props) => {return (<FallOutlined {...props}/>)}
export const AzaFormatLineSpacing = (props) => {return (<FormatLineSpacing {...props}/>)}
export const AzaFilter = (props) => {return (<FilterAltIcon {...props}/>)}
export const AzaForum = (props) => {return (<Forum {...props}/>)}
export const AzaHelp = (props) => {return (<HelpOutline {...props}/>)}
export const AzaIconClose = (props) => {return (<Close {...props}/>)}
export const AzaInsertLink = (props) => {return (<InsertLink {...props}/>)}
export const AzaKeyboardArrowDown = (props) => {return (<KeyboardArrowDown {...props}/>)}
export const AzaKeyboardArrowRight = (props) => {return (<KeyboardArrowRight {...props}/>)}
export const AzaMenuIcon = (props) => {return (<MenuIcon {...props}/>)}
export const AzaMenuFoldIcon = () => {return (<MenuFoldOutlined/>)}
export const AzaMenuUnFoldIcon = () => {return (<MenuUnfoldOutlined/>)}
export const AzaMinusSquareFilled = (props) => {return (<MinusSquareFilled {...props}/>)}
export const AzaNotifications = (props) => {return (<Notifications {...props}/>)}
export const AzaPauseCircleOutline = (props) => {return (<PauseCircleOutline {...props}/>)}
export const AzaPlayCircleOutline = (props) => {return (<PlayCircleOutline {...props}/>)}
export const AzaPowerSettingsNew = (props) => {return (<PowerSettingsNew {...props}/>)}
export const AzaPsychology = (props) => {return (<PsychologyIcon {...props}/>)}
export const AzaRadioButtonChecked = (props) => {return (<RadioButtonChecked {...props}/>)}
export const AzaRiseOutlined = (props) => {return (<RiseOutlined {...props}/>)}
export const AzaSend = (props) => {return (<Send {...props}/>)}
export const AzaSettings = (props) => {return (<Settings {...props}/>)}
export const AzaSync = (props) => {return (<Sync {...props}/>)}
export const AzaTune = (props) => {return (<Tune {...props}/>)}
export const AzaTranslate = (props) => {return (<Translate {...props}/>)}
export const AzaViewColumn = (props) => {return (<ViewColumn {...props}/>)}
export const AzaAutoAwesome = (props) => {return (<AutoAwesome {...props}/>)}
export const AzaMoveToInbox= (props) => {return (<MoveToInbox {...props}/>)}



