import * as React from "react";
import {useCallback, useEffect, useMemo} from "react";
import {useDbItem, useUserRDB} from "../../utils/rdbHooks";
import {API_GET_SITES, QUERY_PARAM_SITE_ID, RDB_USERRIGHTS} from "../../utils/constant";
import {useUser} from "../../app/globalHooks";
import {useAdminModeProvider} from "../../features/adminMode/adminModeContext";
import {useDispatch, useSelector} from "react-redux";
import {
    selectSiteId,
    selectSiteList, selectSiteListLoaded, selectSiteLoaded,
    setSiteId,
    setSiteList as actionSetSiteList, setSiteLoaded,
    setSiteSettings
} from "../../app/globalSlice";
import {useSearchParams} from "react-router-dom";
import {backendFetch, getSiteSettings} from "../../utils/backendHelper";
import {AdminSiteSelector} from "./AdminSiteSelector";
import {UserSiteSelector} from "./UserSiteSelector";
import {actionGetRenewalInfo} from "../../containers/payment/paymentGroup/paymentSlice";


export const SiteSelector = ({isSmallScreen}) => {

    const dispatch = useDispatch()

    const siteList = useSelector(selectSiteList);
    const siteListLoaded = useSelector(selectSiteListLoaded);
    const setSiteList = useCallback((newSiteList) => {
        if (!siteListLoaded || JSON.stringify(Object.keys(siteList).sort()) !== JSON.stringify(Object.keys(newSiteList).sort())) {
            dispatch(actionSetSiteList(newSiteList))
        }
    }, [dispatch, siteList, siteListLoaded])

    // Get the user
    const {user, loading: loadingUser} = useUser()
    // Get the access right to add realtime functionality to the selector
    const {item: userRights, loadingRights} = useDbItem(RDB_USERRIGHTS, user?.uid)
    // Get the rdb user object
    const {recent, changeSite: changeSiteRDB, loading: loadingRDB} = useUserRDB()

    // We will need to know if the user is an admin to render a more complex site selector
    const {isAdmin} = useAdminModeProvider()

    // Get the site id from the redux
    const siteID = useSelector(selectSiteId)
    const siteLoaded = useSelector(selectSiteLoaded)

    // Get the list of site from the user rights objet in the rdb
    const rdbSiteList = useMemo(() => {
        if (loadingRights) return []
        if (userRights?.["sites"] === undefined && user && !loadingUser) {
            // The fetch to the backend might update the frontend user that will update the userRights
            // Will enter here only once because the userRights will be updated with sites at least to {}
            backendFetch({path: API_GET_SITES, user})
                .then(json_data => {
                    // Store the api result in the local state
                    setSiteList(json_data)
                })
        }
        // Return the list of sites from the user rights
        return Object.keys(userRights?.["sites"] ?? {})
    }, [loadingRights, userRights, user, loadingUser, setSiteList])

    // Get the site id from the query params
    const [searchParams, setSearchParams] = useSearchParams();
    // No need to memoize something this small
    const queryParamSiteID = useMemo(() => decodeURI(searchParams.get(QUERY_PARAM_SITE_ID) ?? null), [searchParams]);

    // TODO: How to avoid an infinite loop if the 2 list of access right are different (due to a bug)
    // TODO: Maybe put a delay after the first try?
    useEffect(() => {
        if (loadingUser || !user) return
        // Then check if it's an admin user
        if (isAdmin) {
            // TODO: We need a mechanism to update the list of site for an admin user, last site id in rdb for example
            // If the list of site has not been loaded yet, get it from the backend
            if (Object.keys(siteList).length === 0) {
                // If it's an admin, we will get the list of site from the backend
                backendFetch({path: API_GET_SITES, user})
                    .then(json_data => {
                        // Store the api result in the local state
                        setSiteList(json_data)
                    })
            }
            // No need to continue if it's an admin
            return
        }
        // First we need to make sure the list of site has changed
        if (JSON.stringify(rdbSiteList.sort()) !== JSON.stringify(Object.keys(siteList).sort())) {
            const qp = {}
            if (Object.keys(siteList).length > 0) {
                qp.resync = true
            }
            backendFetch({path: API_GET_SITES, user, queryParams: qp})
                .then(json_data => {
                    // Store the api result in the local state
                    setSiteList(json_data)
                })
        }
        // for (const site_id of rdbSiteList) {
        //     // Check if the key exist
        //     if (!(site_id in siteList)) {
        //         // refresh the list of site
        //         backendFetch({path: API_GET_SITES, user, queryParams:{resync:true}})
        //             .then(json_data => {
        //                 // Store the api result in the local state
        //                 setSiteList(json_data)
        //             })
        //         break;
        //     }
        // }
    }, [isAdmin, loadingUser, rdbSiteList, setSiteList, siteList, user])

    // We need to make sure the siteID is never empty
    useEffect(() => {
        let takeQPinfo = false;

        // If the rdb is still loading, do nothing
        if (loadingRDB) return;

        // If the siteID is set, do nothing
        if (siteID) {
            if (!siteLoaded) {
                dispatch(setSiteLoaded(true))
            }
            if (queryParamSiteID === null || queryParamSiteID === 'null') {
                // Make sure to site_id in query params
                searchParams.set(QUERY_PARAM_SITE_ID, `${siteID}`);
                setSearchParams(searchParams);
            }
            return;
        }

        // Make sure we have a list of site available
        if (siteListLoaded)
        {
            return
        }

        // Now we try to select a site id if there are none selected
        // First, read the query param, if there is a site id use this one
        let newSiteID = "";
        if (queryParamSiteID) {
            if (queryParamSiteID in siteList) {
                // Change the site id in the redux
                dispatch(setSiteId(queryParamSiteID))
                newSiteID = queryParamSiteID;
                takeQPinfo = true;
            } else {
                takeQPinfo = false;
            }

        }

        if (!takeQPinfo) {
            // Get from the recent list if possible (should always be available)
            // if not site_id in query params
            if (recent.length > 0) {
                // Select the first site in the recent list
                newSiteID = recent[0]
            } else {
                // Get the first site if no recent, should not happen
                newSiteID = Object.keys(siteList)[0]
            }
            // Change the site id in the redux
            dispatch(setSiteId(newSiteID))
        }

        // Make sure to update the query param
        searchParams.set(QUERY_PARAM_SITE_ID, newSiteID);
        setSearchParams(searchParams);
    }, [dispatch, loadingRDB, queryParamSiteID, recent, searchParams, setSearchParams, siteID, siteList, siteLoaded, user, siteListLoaded])

    useEffect(() => {
        if (!user) return;
        if (!siteID) return;
        getSiteSettings(user, siteID).then(siteSettings => {
            dispatch(setSiteSettings(siteSettings))
        })
        dispatch(actionGetRenewalInfo({user: user, site_id: siteID}))
    }, [user, siteID, dispatch])

    // change site callback, handle all the things linked to a change of site id, rdb update, query param update, etc
    const handleSiteChange = useCallback((newSiteID) => {
        // change the query params
        searchParams.set(QUERY_PARAM_SITE_ID, newSiteID);
        setSearchParams(searchParams);
        // Update the recent list
        changeSiteRDB(newSiteID)
        // Change the redux state
        dispatch(setSiteId(newSiteID))
    }, [changeSiteRDB, dispatch, searchParams, setSearchParams])

    // No need to render anything if there is no data yet
    if (!siteID || siteID === "" || (Object.keys(siteList).length === 0) || !siteList[siteID]) return <></>

    // For now render a simpler widget for customer

    if (!isAdmin) {
        return (
            <UserSiteSelector
                siteID={siteID}
                changeSite={handleSiteChange}
                siteList={siteList}
            />
        )
    }

    // Admin component
    return (
        <AdminSiteSelector
            isSmallScreen={isSmallScreen}
            siteID={siteID}
            changeSite={handleSiteChange}
            siteList={siteList}
            recent={recent}
        />
    )

}

